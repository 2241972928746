import React, { useEffect, useMemo, useRef, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { shallowEqual, useSelector } from "react-redux";

const DropDownMenu = ({ visible, setVisible, selectedBid, setSelectedBid }) => {
  const open = visible;
  const setOpen = setVisible;
  const container = useRef(null);

  const auctionState = useSelector(state => state.auctionReducer, shallowEqual);

  const priceInventoryState = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );

  const itemDetailState = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );

  const prices = priceInventoryState?.prices; // what price should we select? for now will select first object then get its price1 val
  const price1Obj = prices?.[0];

  const MinBidAmt = itemDetailState?.MinBidAmt;
  const highestBid = auctionState?.highestBid;
  const [possibleBids, setPossibleBids] = useState([]);
  const handleClickOutside = event => {
    if (container.current && !container.current.contains(event.target)) {
      setVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useMemo(() => {
    let items = [];
    if (highestBid) {
      items = [
        ...Array.from(Array(10).keys()).map(
          i => (i + 1) * +MinBidAmt + highestBid?.bidPrice
        )
      ];
    } else {
      items = [
        ...Array.from(Array(10).keys()).map(
          i => i * +MinBidAmt + price1Obj?.price_1
        )
      ];
    }
    console.log(items + 5454);
    setPossibleBids(items);
    setSelectedBid(items[0]);
  }, [highestBid?.firstName, highestBid?.bidPrice]);

  return (
    <div className="dropdown-container" ref={container}>
      <button
        type="button"
        className="dropdown__button"
        onClick={() => setOpen(!open)}
      >
        <div></div>
        <div> &#36; {selectedBid || possibleBids?.[0]} </div>
        {!open ? (
          <MdKeyboardArrowDown className="btn-i chevron" />
        ) : (
          <MdKeyboardArrowUp className="btn-i chevron" />
        )}
      </button>
      {open && (
        <div className="dropdown-wrapper">
          <div className="auction__dropdown-menu">
            {possibleBids.map(pb => (
              <div
                className={`dropdown-menu__item ${
                  pb === selectedBid && "dropdown-menu__item-selected"
                }`}
                onClick={() => {
                  setOpen(false);
                  setSelectedBid(pb);
                }}
                key={pb}
              >
                <span>${pb}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDownMenu;
