import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useCallback
} from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  getDeliveryOptions,
  addToLocalMiniCart,
  setAddToCartValidationErrors,
  setProductOutOfStockError,
  addToCartCheckboxProduct,
  resetProductAttributesAction
} from "../../../../redux/actions/productAction";
import { I18nContext } from "../../../../i18n/index";
import { FormattedNumber } from "react-intl";
//import NotificationRequest from "../../Components/NotificationRequest/NotificationRequest"
import Loading from "../../../AC-Loading/Loading";
import GrandTotalCheckBoxItems from "./Components/GrandTotalCheckBoxItems";
import NotifyOnReStock from "../../../AC-Notifications/NotifyOnReStock";
// import Tooltip from "@material-ui/core/Tooltip";
// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import "./Styles/AddToCartBox.css";
import WishListBarMobile from "../WishList/WishListBarMobile";
import Auction from "../Auction";

import ChatTheSeller from "../ChatTheSeller/ChatTheSeller";
import MessageForWinner from "../Auction/Components/MessageForWinner";
import { MdAddShoppingCart } from "react-icons/md";

const WIGGLE_TIMER = 2500;

const AddToCartBox = props => {
  const productProps = props.data.productData._xResult[0].properties;
  const { propvalue } =
    productProps.find(({ propname }) => propname === "Per_Unit") || {};

  const dispatch = useDispatch();
  const { translate, currency, priceConvert } = useContext(I18nContext);

  /* const [listPrice, setListPrice] = useState(
    props.priceInv.prices[0].listprice || 0
  ); */

  const supplierInfoState = useSelector(
    state => state.productReducer.supplierInfo,
    shallowEqual
  );

  const priceState = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );

  const [firstDistId, setFirstDistId] = useState(0);

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const [price, setPrice] = useState(0);


  const [numberOfItems, setNumberOfItems] = useState(1);
  const [shouldWiggle, setShouldWiggle] = useState(false);
  const [perUnitState, setPerUnitState] = useState("");
  const [inStock, setInStock] = useState({
    initial: true,
    nextshipdate: "",
    discontinued: false,
    instock: false,
    hide: false,
    qty: ""
  });

  const initialValue = useRef(true);

  useEffect(() => {
    if (
      props &&
      props.priceInv &&
      props.priceInv.prices.length > 0 &&
      Object.keys(props.priceInv.prices[0]).includes("price_1")
    ) {
      console.log("priceSet");
      setPrice(props.priceInv.prices[0].price_1);
    }
  }, [props.priceInv]);
  const checkBoxItemsState = useSelector(
    state => state.productReducer.checkboxItems,
    shallowEqual
  );

  const selectedCheckBoxItemsState = useSelector(
    state => state.productReducer.selectedCheckboxItems,
    shallowEqual
  );

  console.log("supplierInfoState", supplierInfoState);

  const isProductDetailsLoading = useSelector(
    state => state.productReducer.loading,
    shallowEqual
  );

  const attributesState = useSelector(
    state => state.productReducer.itemDetail.attributes,
    shallowEqual
  );

  const attributeid =
    attributesState && attributesState[0] && attributesState[0].attributeid;

  const productAttributeCheckboxFlagState = useSelector(
    state => state.productReducer.productAttributeCheckboxFlag,
    shallowEqual
  );

  const selectedProductCheckboxAttributesState = useSelector(
    state => state.productReducer.selectedProductCheckboxAttributes,
    shallowEqual
  );

  const requestingAddToCartState = useSelector(
    state => state.productReducer.requestingAddToCart,
    shallowEqual
  );

  const mainItemIdState = useSelector(
    state => state.productReducer.itemDetail.mainitemid,
    shallowEqual
  );

  const skusState = useSelector(
    state => state.productReducer.itemDetail.skus,
    shallowEqual
  );

  const itemIdState = useSelector(
    state => state.productReducer.itemDetail.itemid,
    shallowEqual
  );

  const selectedProductAttributesState = useSelector(
    state => state.productReducer.selectedProductAttributes,
    shallowEqual
  );

  const attributeDetailsState = useSelector(
    state => state.productReducer.itemDetail.attributeDetails,
    shallowEqual
  );

  const productInitialProperties = useSelector(
    state => state.productReducer.productInitial.properties,
    shallowEqual
  );

  useEffect(() => {
    if (isProductDetailsLoading) {
      setPerUnitState(null);
    } else {
      if (productInitialProperties && productInitialProperties.length > 0) {
        let perUnitProp = productInitialProperties.find(prop =>
          ["Per Unit", "Per-Unit", "Per_Unit"].includes(Object.keys(prop)[0])
        );

        console.info({ productInitialProperties, perUnitProp });
        if (perUnitProp) setPerUnitState(Object.values(perUnitProp)[0]);
      }
    }
  }, [isProductDetailsLoading, productInitialProperties]);

  useEffect(() => {
    if (!isProductDetailsLoading) {
      document.getElementById("loadIcon").classList.add("hidden");
    }
  }, [isProductDetailsLoading]);

  useEffect(() => {
    if (
      priceState &&
      Object.keys(priceState).length > 0 &&
      supplierInfoState &&
      supplierInfoState[0] &&
      supplierInfoState[0].distributorOrder
    ) {
      const firstDistId = supplierInfoState[0]?.distributorOrder[0]?.distid;
      setFirstDistId(firstDistId);
      setPrice(
        priceState.prices.find(inv => inv.distributorId == firstDistId) &&
          priceState.prices.find(inv => inv.distributorId == firstDistId)
            .price_1
      );

      // determine stock status

      const instockQty =
        priceState.invs && priceState.invs[0] && priceState.invs[0].instock;

      const hidden =
        priceState.invs && priceState.invs[0] && priceState.invs[0].hide;

      const discontinued =
        priceState.invs &&
        priceState.invs[0] &&
        priceState.invs[0].discontinued;

      const nextshipdate =
        priceState.invs &&
        priceState.invs[0] &&
        priceState.invs[0].nextshipdate;

      setInStock({
        initial: false,
        nextshipdate,
        discontinued,
        instock: instockQty > 0,
        hide: hidden,
        qty: instockQty
      });
    }
  }, [priceState, supplierInfoState]);

  useEffect(() => {
    // if (!inStock.initial){
    //   props.handleLoading(false);
    // }
    if (props.loading) {
      setInStock({
        ...inStock,
        initial: true
      });
    }
  }, [props.loading]);

  useEffect(() => {
    let timer = null;
    if (numberOfItems) {
      if (initialValue.current) {
        initialValue.current = false;
        return;
      }

      timer = setTimeout(() => {
        setShouldWiggle(true);
      }, WIGGLE_TIMER);
    }
    return () => {
      setShouldWiggle(false);
      clearTimeout(timer);
    };
  }, [numberOfItems]);

  const handleAddToCart = (quoteMode = false) => {
    if (!inStock.instock && inStock.discontinued) {
      dispatch(setProductOutOfStockError(true));
      return;
    }
    let attributesObject = null;

    let requiredFields = [];

    if (productAttributeCheckboxFlagState.checkbox) {
      console.log("handleAddToCart", productAttributeCheckboxFlagState);
      let expectedAttributeIdAndScreenName = "";
      if (attributeDetailsState && attributeDetailsState.length > 0) {
        expectedAttributeIdAndScreenName = attributeDetailsState[0];
      }

      let hasSelectedAttribute =
        selectedProductCheckboxAttributesState &&
        selectedProductCheckboxAttributesState[
          expectedAttributeIdAndScreenName.attributeid
          ] &&
        selectedProductCheckboxAttributesState[
          expectedAttributeIdAndScreenName.attributeid
          ].length > 0;

      if (!hasSelectedAttribute)
        requiredFields.push(expectedAttributeIdAndScreenName.screenname);
    } else {
      if (
        selectedProductAttributesState.hasOwnProperty(
          mainItemIdState || itemIdState
        )
      ) {
        attributesObject =
          selectedProductAttributesState[mainItemIdState || itemIdState];

        let expectedAttributes = attributeDetailsState.reduce((p, c) => {
          const { screenname, attributeid } = c;
          p = [...p, { screenname, attributeid }];
          return p;
        }, []);

        let receivedAttributeIds = Object.keys(attributesObject);

        console.info(
          "addToCartTest",
          attributesObject,
          expectedAttributes,
          receivedAttributeIds
        );

        expectedAttributes.forEach(attr => {
          if (!receivedAttributeIds.includes(attr.attributeid.toString()))
            requiredFields.push(attr.screenname);
        });
      } else {
        if (attributeDetailsState && attributeDetailsState.length > 0) {
          let expectedAttributes = attributeDetailsState.reduce((p, c) => {
            const { screenname, prompt, attributeid } = c;
            p = [...p, { screenname, prompt, attributeid }];
            return p;
          }, []);

          expectedAttributes.forEach(attr => {
            requiredFields.push(attr.screenname || attr.prompt);
          });
        }
      }
    }

    dispatch(setAddToCartValidationErrors(requiredFields));
    if (requiredFields.length > 0) {
      return;
    }
    let firstDist = supplierInfoState[0]?.distributorOrder[0];

    let vid = firstDist.supplier_store_vid;

    if (
      productAttributeCheckboxFlagState.checkbox &&
      selectedCheckBoxItemsState &&
      selectedCheckBoxItemsState.length === 0
    ) {
      let itemCount =
        selectedProductCheckboxAttributesState &&
        selectedProductCheckboxAttributesState[attributeid] &&
        selectedProductCheckboxAttributesState[attributeid].length;

      let priceInventory =
        selectedProductCheckboxAttributesState &&
        selectedProductCheckboxAttributesState.priceInventory;
      let products = [];

      let keys = Object.keys(priceInventory).filter(key =>
        selectedProductCheckboxAttributesState[attributeid].includes(
          Number(key)
        )
      );

      let outOfStockAll = true;
      for (let key of keys) {
        let product = {};
        product.attributes = [attributeid, key];
        product.optionId = key;
        product.id = priceInventory[key].itemid;
        product.distributorId = priceInventory[key].invs[0].distributorId;
        product.qty =
          productAttributeCheckboxFlagState.format === "radio"
            ? numberOfItems
            : priceInventory[key].qty; // due to the difference between setting the quantity for when the attributte is single and when it's rendered as radio vs checkbox

        //if out of stock don't add to the list of products and reduce the number of selected items
        if (priceInventory[key].invs[0].instock) {
          products.push(product);
          outOfStockAll = false;
        } else {
          itemCount -= 1;
        }
      }

      if (outOfStockAll) {
        dispatch(setProductOutOfStockError(true));
        return;
      }

      dispatch(
        addToCartCheckboxProduct(
          itemIdState,
          itemCount,
          products,
          0,
          false,
          vid
        )
      );
    } else if (
      productAttributeCheckboxFlagState.checkbox &&
      selectedCheckBoxItemsState &&
      selectedCheckBoxItemsState.length > 0
    ) {
      let itemCount =
        selectedProductCheckboxAttributesState &&
        selectedProductCheckboxAttributesState[attributeid] &&
        selectedProductCheckboxAttributesState[attributeid].length;

      itemCount += selectedCheckBoxItemsState.length;

      let priceInventory =
        selectedProductCheckboxAttributesState &&
        selectedProductCheckboxAttributesState.priceInventory;
      let products = [];

      let keys = Object.keys(priceInventory).filter(key =>
        selectedProductCheckboxAttributesState[attributeid].includes(
          Number(key)
        )
      );

      let outOfStockAll = true;
      for (let key of keys) {
        let product = {};
        product.attributes = [attributeid, key];
        product.optionId = key;
        product.id = priceInventory[key].itemid;
        product.distributorId = priceInventory[key].invs[0].distributorId;
        product.qty = priceInventory[key].qty;

        //if out of stock don't add to the list of products and reduce the number of selected items
        if (priceInventory[key].invs[0].instock) {
          products.push(product);
          outOfStockAll = false;
        } else {
          itemCount -= 1;
        }
      }

      // add the extras to the products
      selectedCheckBoxItemsState.forEach(item => {
        let product = {};
        product.id = item.id;
        product.distributorId = item.distId;
        product.qty = item.qty;
        products.push(product);
      });

      if (outOfStockAll) {
        dispatch(setProductOutOfStockError(true));
        return;
      }

      /*  let attributesObject = {};

                  attributesObject = selectedProductCheckboxAttributesState[
                    attributeid
                  ].map(optionid => {
                    return [attributeid, optionid];
                  }); */

      dispatch(
        addToCartCheckboxProduct(
          itemIdState,
          itemCount,
          products,
          0,
          false,
          vid
        )
      );
    } else if (
      selectedCheckBoxItemsState &&
      selectedCheckBoxItemsState.length > 0
    ) {
      let itemCount = 1 + selectedCheckBoxItemsState.length;
      let products = [];

      // add the actual product to the list of products
      let productItself = {
        distributorId: priceState.prices.find(
          inv => inv.distributorId == firstDistId
        ).distributorId,
        id: priceState.itemid,
        qty: 1
      };

      products.push(productItself);

      selectedCheckBoxItemsState.forEach(item => {
        let product = {};
        product.id = item.id;
        product.distributorId = item.distId;
        product.qty = item.qty;
        products.push(product);
      });

      dispatch(
        addToCartCheckboxProduct(
          itemIdState,
          itemCount,
          products,
          0,
          attributesObject,
          false,
          vid
        )
      );
    } else {
      dispatch(
        getDeliveryOptions(
          priceState?.prices?.find(inv => inv.distributorId == firstDistId)
            .distributorId,
          priceState.code,
          numberOfItems,
          priceState.itemid,
          attributesObject,
          quoteMode,
          vid
        )
      );
    }
  };

  const renderAuction = useCallback(() => {
    if (props.IS_AUCTION) return <Auction handleAddToCart={handleAddToCart} />;
    else return null;
  }, [
    props.IS_AUCTION,
    handleAddToCart,
    supplierInfoState,
    priceState,
    firstDistId
  ]);

  const handleSetQuantityInput = e => {
    const value = Number(e.target.value);
    if (value > 0 && String(value).length <= 9)
      setNumberOfItems(Number(e.target.value));
  };

  const renderPerUnitText = () => {
    if (perUnitState) {
      return (
        <strong className="add-to-cart-box--per-unit-text">
          {perUnitState}
        </strong>
      );
    } else return null;
  };

  return (
    <React.Fragment>
      <div id="addToCardGrid">
        {renderAuction()}
        <div id="pickupShipInfo"></div>
        {!!props.IS_AUCTION === false && (
          <div className="buyBox">
            <div className="buy-box-qty-price-container">
              <div className="inStock-container" style={{ display: "none" }}>
                {inStock ? (
                  <div className="inStock-wrapper">
                    <label>{translate("js.item.stock")}</label>
                    &nbsp;
                    <span style={{ fontWeight: "400" }}>{inStock.qty}</span>
                  </div>
                ) : null}
              </div>
              {!isProductDetailsLoading && (
                <div
                  className="buy-box-qty-wrapper"
                  style={{
                    display:
                      productAttributeCheckboxFlagState ||
                      (checkBoxItemsState && checkBoxItemsState.length > 0)
                        ? ""
                        : ""
                  }}
                >
                  <div
                    /*   style={{
                    visibility: isProductDetailsLoading ? "hidden" : "visible"
                  }} */
                    className="buy-box-price-wrapper"
                  >
                    <div id="buyBoxPrice">
                      {/* <span>{isProductDetailsLoading.toString()}</span> */}
                      {price ? (
                        <FormattedNumber
                          value={price / priceConvert}
                          style="currency"
                          currency={currency}
                        />
                      ) : (
                        "Free"
                      )}
                      {/* {isProductDetailsLoading ? (
                  <Loading className="add-to-cart-box--price-loading" />
                ) : price ? (
                  <FormattedNumber
                    value={price / priceConvert}
                    style="currency"
                    currency={currency}
                  />
                ) : (
                  "Free"
                )} */}

                      {/*<span className="symbol">
                {currencyState.sign && currencyState.sign}
              </span>
              <span className="dollars">{price}</span>
                  {currencyState.code && currencyState.code}*/}
                    </div>
                    {/* <NotificationRequest /> */}
                    {renderPerUnitText()}
                  </div>
                  <div id="buyBoxQtyBox">
                    {propvalue !== "per participant" ? (
                      <div className="qtyControlsBox">
                        <div className="qtyControlsBtns">
                          <div
                            className="qtyControlsPlus no-select"
                            onClick={() => setNumberOfItems(numberOfItems + 1)}
                            style={{ cursor: "pointer" }}
                          >
                            <span>+</span>
                          </div>
                          <div
                            className="qtyControlsMinus no-select"
                            onClick={() => {
                              if (numberOfItems - 1 > 0) {
                                setNumberOfItems(numberOfItems - 1);
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <span>-</span>
                          </div>
                        </div>
                        <input
                          size={String(numberOfItems).length}
                          className="qtyControlsInput"
                          type="text"
                          value={numberOfItems}
                          onChange={e => {
                            handleSetQuantityInput(e);
                          }}
                        />
                        <div className="clearfix"></div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>

            <div className="">
              <div className="col-xs-12">
                <div id="buyBoxItemInfo2" style={{ fontSize: "1.4em" }}>
                  <GrandTotalCheckBoxItems price={price} />
                </div>
              </div>
            </div>

            <div className="row" style={{ width: "95%" }}>
              <div className="col-xs-12 stockPage" style={{ padding: "0" }}>
                {props.productUnavailable === false ? (
                  <div
                    id="buyBoxAddToCartBtn"
                    className={inStock ? "" : "out-of-stock"}
                  >
                    {!isProductDetailsLoading &&
                    (inStock.instock || !inStock.discontinued) ? (
                      <div
                        tabIndex="0"
                        className={`addToCartBtn${
                          shouldWiggle ? " wiggle" : ""
                        }`}
                        title={translate("js.item.addtocart")}
                        onClick={() => handleAddToCart()}
                        onKeyDown={e => {
                          if (e.key === "Enter") {
                            handleAddToCart();
                          }
                        }}
                      >
                        <div>
                          <span>{translate("js.item.addtocart")} </span>
                        </div>
                        <div>
                          <i className="material-icons add-icon">
                            <MdAddShoppingCart />
                          </i>
                        </div>
                      </div>
                    ) : !inStock.instock && inStock.discontinued ? (
                      <div>Sorry, this product is currently out of stock.</div>
                    ) : (
                      <Loading className="addToCartLoading" />
                    )}
                    {requestingAddToCartState ? (
                      <Loading className="addToCartLoading" />
                    ) : null}
                    {/* <ClickAwayListener onClickAway={handleTooltipClose}>
                      <Tooltip
                        PopperProps={{
                          disablePortal: true
                        }}
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title="We value your privacy and security.  Our security system encrypts your information during transmission.  Your credit card details  are sent do a payment processor and are not shared with this site or any third party sellers."
                      >
                        <div
                          className="Securetransaction"
                          onClick={handleTooltipOpen}
                        >
                          <i className="material-icons">lock_outline</i> Secure
                          transaction
                        </div>
                      </Tooltip>
                    </ClickAwayListener> */}

                    <div></div>
                  </div>
                ) : (
                  <p style={{ margin: "20px 0" }}>
                    SORRY, THIS PRODUCT IS CURRENTLY UNAVAILABLE
                  </p>
                )}

                {/*         <div
                id="buyBoxQuoteBtn"
                className={`add-to-order${inStock ? "" : " active"}`}
              >
                <div
                  className="addToCartBtn"
                  title="Add to Order"
                  onClick={() => handleAddToCart(true)}
                >
                  <div>
                    <span>Add to Order</span>
                  </div>
                  <div>
                    <i className="material-icons add-icon">description</i>
                  </div>
                </div>
              </div> */}
                {/* <div id="buyBoxEnquiryBtn">
                <div
                  id="enquiry-204"
                  className="addToCartBtn sendEnquiryBtn"
                  title={translate("js.item.enquiry")}
                  onClick={handleEnquiryModalOpenClicked}
                >
                  <div>{translate("js.item.enquiry")}</div>
                  <div>
                    <span>
                      <i className="material-icons add-icon">mail_outline</i>
                    </span>
                  </div>
                </div>
              </div> */}

                {/* {isMobileState && (
                <WishListBarMobile
                  data={props.data}
                  productUnavailable={props.productUnavailable}
                  price={price}
                />
              )} */}

                {!inStock && (
                  <React.Fragment>
                    <div className="add-to-cart-stock-status">
                      The supplier may still be in progress updating the
                      inventory for this product. Click the Chat button below to
                      chat with the supplier to confirm availability.
                    </div>
                    {/* <NotifyOnReStock
                      supplier={{
                        stock: priceState.invs[0].instock,
                        code: priceState.code,
                        itemid: priceState.itemid
                      }}
                      renderedInsideAddToCartBox={true}
                    /> */}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        )}
        {/*<ChatTheSeller storeSellerData={props.storeInfo.storeSellerData} />*/}
      </div>
    </React.Fragment>
  );
};

export default AddToCartBox;
