import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import * as classes from "./Styles/ConfirmDeliveryOptions.module.css";
import { confirmActions } from "../../../../../../redux/types";
import Options from "./Components/Options";
import { PROJECT_LINK } from "../../../../../../project-config";

const ConfirmDeliveryOptions = () => {
  const dispatch = useDispatch();

  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const showConfirmState = useSelector(
    state => state.confirmReducer.showConfirm,
    shallowEqual
  );

  const confirmMessage = useSelector(
    state => state.confirmReducer.confirmMessage,
    shallowEqual
  );

  console.log("confirmMessage", confirmMessage);

  const handleYesButtonClicked = itemIsAlreadyInBasket => {
    if (itemIsAlreadyInBasket) {
      window.location.href = `${PROJECT_LINK}/basket.html?vid=${confirmMessage.vid}&ml=en`;
      return;
    }
    dispatch({ type: confirmActions.CONFIRM_YES });
  };

  const handleNoButtonClicked = () => {
    dispatch({ type: confirmActions.CONFIRM_NO });
  };

  useEffect(() => {
    return () => {
      //   dispatch(confirmActions.CONFIRM_NO);
    };
  }, []);

  if (showConfirmState && confirmMessage) {
    const { title, note, cancel, ok, options, message } =
      confirmMessage.__Result;

    const itemIsAlreadyInBasket = title.includes(
      "0 of this item can be purchased"
    );
    return (
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <div className={classes.head}>
            <h6>
              {itemIsAlreadyInBasket
                ? "This item is already in your basket."
                : title || message}
            </h6>
          </div>
          <div className={classes.body}>
            <Options {...{ options, setButtonsDisabled }} />
            <p>
              {itemIsAlreadyInBasket
                ? "Click the View Cart icon at the top right of this page or click YES  to view your basket"
                : note}
            </p>
            <div className={classes.buttons}>
              {cancel && (
                <button
                  onClick={handleNoButtonClicked}
                  className={classes.buttonNo}
                >
                  {cancel}
                </button>
              )}
              {ok && (
                <button
                  onClick={() => handleYesButtonClicked(itemIsAlreadyInBasket)}
                  className={classes.buttonYes}
                >
                  {ok}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ConfirmDeliveryOptions;
