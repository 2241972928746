// import moment from "moment";
import moment from 'moment-timezone';


export const timezoneFormatted = (date) => moment.tz(date, 'America/Toronto');

// some defs for the countdown timer
const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;


export const countDownTimer = (date) => {
  const now = moment();
  const delta = date - now;
  const days = Math.floor(delta / (DAY));
  const hours = Math.floor(
    (delta % (DAY)) / (HOUR)
  );
  const minutes = Math.floor((delta % (HOUR)) / (MINUTE));
  const seconds = Math.floor((delta % (MINUTE)) / SECOND);
  return {delta, days, hours, minutes, seconds};
}

export const timeValSort = async (bidHistory) => {
  try {
    // console.log('---------------- SORTING ----------------');
    return new Promise(resolve => {
      const res = bidHistory.sort((a, b) => (a.bidPrice > b.bidPrice ? 1 : -1)); 
      resolve(res);
    })
          /*
          ||  (new Date(a.timestamp).getTime() > new Date(b.timestamp).getTime() ? 1 : -1) for next weeks simultanuous bids
          */ 
  } catch(e) {
    console.log(e)
    console.error('bidHistory must be of array type. sorry');
    return bidHistory;
  }
}