// extracted by mini-css-extract-plugin
export var button = "WriteReviewModal-module--button--SLkh2";
export var container = "WriteReviewModal-module--container--NGjca";
export var errorText = "WriteReviewModal-module--errorText--Tdefb";
export var fileUpload = "WriteReviewModal-module--fileUpload--TksWB";
export var fileUploadButton = "WriteReviewModal-module--fileUploadButton--4KQIt";
export var fileUploadContainer = "WriteReviewModal-module--fileUploadContainer--zQkjn";
export var fileUploadDescriptionText = "WriteReviewModal-module--fileUploadDescriptionText--uzaog";
export var fileUploadInput = "WriteReviewModal-module--fileUploadInput--DQeY4";
export var fileUploadWrapper = "WriteReviewModal-module--fileUploadWrapper--ah9aD";
export var input = "WriteReviewModal-module--input--Fcqdm";
export var itemTitle = "WriteReviewModal-module--itemTitle--jgoCr";
export var rating = "WriteReviewModal-module--rating--H4tug";
export var reviewDetails = "WriteReviewModal-module--reviewDetails--msRtx";
export var reviewTitle = "WriteReviewModal-module--reviewTitle--jXVVq";
export var submit = "WriteReviewModal-module--submit--0Zycf";
export var successText = "WriteReviewModal-module--successText--OJcSn";
export var title = "WriteReviewModal-module--title--Cj00n";
export var uploadedImageContainer = "WriteReviewModal-module--uploadedImageContainer--EoAfq";
export var uploadedImageRemoveIcon = "WriteReviewModal-module--uploadedImageRemoveIcon--XyBag";
export var uploadedImageWrapper = "WriteReviewModal-module--uploadedImageWrapper--vhQ7v";
export var wrapper = "WriteReviewModal-module--wrapper--Q9zSW";