import React, { useState, useMemo, useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import DropDown from "./DropDown";
import { MdAddShoppingCart } from "react-icons/md";
import { addNewBid } from "../../../../../redux/actions/auctionActions";
import { addAuctionItemToWishList } from "../../../../../redux/actions/wishListActions";
import Confirm from "./Confirm";
import { timezoneFormatted } from "../utils";
import MessageForWinner from "./MessageForWinner";
import { auctionAddToCartSuccessFunc } from "../../../../../redux/actions/productAction";
import { useCountDownTimer } from "../hooks";

const Actions = ({ handleAddToCart }) => {
  const [addToCartLoading, setAddToCartLoading] = useState(false);
  const itemDetailState = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );
  // console.log("addToCartLoading", addToCartLoading);
  const auctionBasketState = useSelector(
    state => state.sessionReducer.suppliersBasket,
    shallowEqual
  );

  const supplierInfo = useSelector(
    state =>
      state.productReducer.supplierInfo?.[0]?.distributorOrder?.[0]
        ?.supplier_store_vid,
    shallowEqual
  );

  // console.log("auctionBasketState", auctionBasketState);
  // console.log("supplierInfo", supplierInfo);
  const auctionState = useSelector(state => state.auctionReducer, shallowEqual);
  const priceInventoryState = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );
  const productLinkState = useSelector(
    state => state.productReducer.productInitial.productLink,
    shallowEqual
  );
  const wishListState = useSelector(
    state => state.wishListReducer.wishlist,
    shallowEqual
  );
  const productState = useSelector(
    state => state.productReducer.product,
    shallowEqual
  );
  const { loginName, firstName, lastName, securityToken, clientId } =
    useSelector(state => state.loginReducer, shallowEqual);

  const lastNameInitial = lastName[0];

  const email = loginName;
  const bidHistory = auctionState?.bidHistory || [];

  let itemCode = itemDetailState?.code;

  const dispatch = useDispatch();
  const highestBid = auctionState?.highestBid;
  const [visible, setVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [selectedBid, setSelectedBid] = useState(0.0);
  const earlyCloseMinutes = +itemDetailState?.earlycloseminutes; // convert earlycloseminutes to numerical mins format

  const startTime = timezoneFormatted(auctionState?.startTime);
  let endTime = timezoneFormatted(auctionState?.endTime);
  let currentEndTime = timezoneFormatted(auctionState?.endTime);
  const countDownForEndTime = useCountDownTimer(currentEndTime, bidHistory);
  endTime = earlyCloseMinutes
    ? endTime.subtract(earlyCloseMinutes, "minutes")
    : endTime;

  let isStartInFuture = startTime?.isAfter();
  const auctionIsEnded =
    auctionState?.status === "closed" || endTime.isBefore(); // endTime.isBefore() && , disable auction actions only when status closed?

  const blockTimeIsEnded =
    currentEndTime.isBefore() || auctionState?.status === "closed";
  const currUserIsWinner = useMemo(() => {
    return clientId && clientId === highestBid?.clientId;
  }, [highestBid]);

  const addToWishlist = async () => {
    // someone gotta go and fix the props though, now it only adds a semi-empty item object
    let price = {};
    const pPrice = priceInventoryState?.prices?.[0].price_1;
    console.info(pPrice);
    let integer = "0";
    let decimal = "0";
    if (String(pPrice).includes(".")) {
      let pairs = String(pPrice).split(".");
      integer = pairs[0];
      decimal = pairs[1];
    } else {
      integer = String(pPrice);
    }
    price = {
      value: {
        integer,
        decimal
      }
    };
    price = {
      value: {
        integer: String(Math.floor(selectedBid)),
        decimal: String(Math.round((selectedBid % 1) * 100))
      }
    };

    console.info("borop wish price", price);

    let productUrl = productLinkState.split("product/")[1];

    if (productUrl.includes("?")) productUrl = productUrl.split("?")[0];

    dispatch(
      addAuctionItemToWishList(
        itemDetailState.itemId,
        itemDetailState.code,
        itemDetailState.title,
        itemDetailState.shortdesc,
        productState.currency_sign,
        itemDetailState.image,
        price,
        productUrl,
        wishListState
      )
    );
  };

  const renderEndingTime = () => {
    return (
      <p className="time">
        {countDownForEndTime.initial === false && (
          <p className="time">
            {countDownForEndTime?.days !== "0" && (
              <>
                {countDownForEndTime?.days}{" "}
                <span>
                  {countDownForEndTime?.days === "1" ? `day ` : `days `}
                </span>
              </>
            )}
            {countDownForEndTime?.hours !== "0" && (
              <>
                {countDownForEndTime?.hours}{" "}
                <span>
                  {countDownForEndTime?.hours === "1" ? `hour ` : `hours `}
                </span>
              </>
            )}
            {countDownForEndTime?.minutes !== "0" && (
              <>
                {countDownForEndTime?.minutes}{" "}
                <span>
                  {countDownForEndTime?.minutes === "1"
                    ? `minute `
                    : `minutes `}
                </span>
              </>
            )}
            {countDownForEndTime?.seconds !== "0" && (
              <>
                {countDownForEndTime?.seconds}{" "}
                <span>
                  {countDownForEndTime?.seconds === "1"
                    ? `second `
                    : `seconds `}
                </span>
              </>
            )}
          </p>
        )}
      </p>
    );
  };

  useEffect(() => {
    setAddToCartLoading(false);
  }, [auctionBasketState]);

  const submitNewBid = async () => {
    await addToWishlist(); // needs fixes for now

    const acode = auctionState?.acode;
    const vid = itemDetailState?.storevid;
    const bidPrice = selectedBid;

    const params = {
      acode,
      vid,
      itemcode: itemCode,
      bidPrice,
      firstName,
      lastNameInitial,
      email
    };
    if (auctionIsEnded) {
      setConfirmVisible(false);
    } else {
      await dispatch(addNewBid(params));
      setConfirmVisible(false);
    }
  };

  if (!auctionState?.auctionFetched || auctionState?.status === "new") {
    return null;
  }

  const loginOrConfirm = () => {
    if (!securityToken) {
      document.getElementById("login-icon-btn").click();
    } else {
      setConfirmVisible(true);
    }
  };

  return (
    <div className="auction__actions">
      {/* <button onClick={() => addToWishlist()}>wishlist</button> */}
      {!auctionIsEnded &&
        !isStartInFuture && // both of these values should be true, even if an admin puts start in future and end in future
        (!securityToken ? (
          <div className="login-btn" onClick={() => loginOrConfirm()}>
            <span>Create an account or login to bid on this item</span>
          </div>
        ) : (
          <>
            <div className="now" onClick={() => loginOrConfirm()}>
              <span className="bid-field">BID</span>
            </div>
            <div className="more">
              <DropDown
                visible={visible}
                setVisible={setVisible}
                selectedBid={selectedBid}
                setSelectedBid={setSelectedBid}
              />
            </div>
          </>
        ))}
      {auctionIsEnded && !isStartInFuture && highestBid && blockTimeIsEnded ? (
        <div className="results">
          {currUserIsWinner ? (
            <>
              <p>
                <strong>You</strong> were the highest bidder. Please add this
                item to your basket and pay for your purchase.
              </p>
              <div
                className="add-to-cart"
                disabled={addToCartLoading}
                onClick={
                  () =>
                    auctionBasketState &&
                    supplierInfo &&
                    auctionBasketState?.[supplierInfo]?.products.some(
                      item =>
                        item.itemcode === itemDetailState.code ||
                        item.compCode === itemDetailState.code
                    )
                      ? dispatch(auctionAddToCartSuccessFunc(true))
                      : [handleAddToCart(false), setAddToCartLoading(true)] // this is the function that adds to cart
                }
              >
                <MdAddShoppingCart />
                <span>Add To Cart</span>
              </div>
            </>
          ) : (
            <p>
              <>
                The winner is{" "}
                <strong>
                  {highestBid?.firstName} {highestBid?.lastName[0]}
                </strong>
              </>

              {!loginName && (
                <div className="login-btn" onClick={() => loginOrConfirm()}>
                  <span>Login if you are the winner!</span>
                </div>
              )}
            </p>
          )}
          <MessageForWinner />
        </div>
      ) : currUserIsWinner && auctionIsEnded ? (
        <div className="results">
          <>
            <p>
              Congratulations! You had the highest bid. Please come back in
              {renderEndingTime()}
              to purchase your product
            </p>
          </>
        </div>
      ) : null}
      <Confirm
        visible={confirmVisible}
        selectedBid={selectedBid}
        setVisible={setConfirmVisible}
        submit={submitNewBid}
        currUserIsWinner={currUserIsWinner}
      />
    </div>
  );
};

export default Actions;
