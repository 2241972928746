/* Copyright 2020 Avetti.com Corporation - All Rights Reserved

This source file is subject to the Avetti Commerce Front End License (ACFEL 1.20)
that is accessible at https://www.avetticommerce.com/license */
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import "../../assets/css/product-sl.css";
import "./Styles/OFBProductPage.css";

import ImageCarousel from "./Components/ImageCarousel/BDImageCarousel";
import ReviewBar from "./Components/Review/ReviewBar";
import ReviewBarMobile from "./Components/Review/ReviewBarMobile";
import Attributes from "./Components/Attributes/BDAttributes";
import AddToCartBox from "./Components/AddToCart/AddToCartBox";
import AddedToCartModal from "./Components/AddToCart/Components/AddedToCartModal";
import Reviews from "./Components/Review/components/Reviews";

import htmldecoder, { htmlDecode } from "../../functions/htmldecoder";
import CheckBoxAddons from "./Components/CheckBoxAddons/CheckBoxAddons";
import ShareButtons from "./Components/ShareButtons/ShareButtons";
import WishListBar from "./Components/WishList/WishListBar";
import ItemCode from "./Components/ItemCode/ItemCode";
import OtherInfoTab from "./Components/OtherInfoTab/OtherInfoTab";
import { useSwipeable } from "react-swipeable";

import Breadcrumb from "./Components/Breadcrumb/Breadcrumb";
import { I18nContext } from "../../i18n/index";
import { useLocation } from "@reach/router";
import {
  fetchDirectUrlGetItem,
  getSupplierInfo,
  mapProductUrls,
  unMountProductPageAction
} from "../../redux/actions/productAction";
import ConfirmDeliveryOptions
  from "../AC-ProductPage/Components/AddToCart/Components/ConfirmDeliveryOptions/ConfirmDeliveryOptions";
import CartValidationErrors from "../AC-ProductPage/Components/CartValidationErrors/CartValidationErrors";
import OutOfStockError from "../AC-ProductPage/Components/CartValidationErrors/OutOfStockError";

import fbLogo from "../../assets/img/sellerFB.png";
import twLogo from "../../assets/img/sellerTW.png";
import igLogo from "../../assets/img/sellerIG.png";
import ptLogo from "../../assets/img/sellerPT.png";
import {
  backToCategory,
  setGoBackToCategoryFromProductPage
} from "../../redux/actions/categoryActions";
import { isArray } from "../../functions/Utilities";

import sponsorLogo1 from "../../assets/img/sponsors/Barrie360-stacked-logotagline.png";
import sponsorLogo2 from "../../assets/img/sponsors/Rock95-logotagline.png";
import sponsorLogo3 from "../../assets/img/sponsors/KoolfFM-logotagline.png";
import sponsorLogo4 from "../../assets/img/sponsors/80_McDonalds_ArchesLogo.jpg";
import sponsorLogo5 from "../../assets/img/sponsors/Abercrombie-Electric-Logo.jpg";
import sponsorLogo6 from "../../assets/img/sponsors/TS_Black_CMYK.png";
import sponsorLogo7 from "../../assets/img/sponsors/wildwood-Logo.jpg";
import sponsorLogo8 from "../../assets/img/sponsors/wallwin.jpg";
import sponsorLogo9 from "../../assets/img/sponsors/telequip-logo.jpg";
import sponsorLogo10 from "../../assets/img/sponsors/sunbelt-logo.jpg";
import sponsorLogo11 from "../../assets/img/sponsors/rogers-logo.png";
import sponsorLogo12 from "../../assets/img/sponsors/HUB-Sarjeant_LOGO.jpg";
import sponsorLogo13 from "../../assets/img/sponsors/NTR-logo.jpg";
import sponsorLogo14 from "../../assets/img/sponsors/NearNorth.jpg";
import sponsorLogo15 from "../../assets/img/sponsors/logo_radio_barrie_big101_nov2016-500x250.png";
import sponsorLogo16 from "../../assets/img/sponsors/Barrie-Advance-logo.png";
import sponsorLogo17 from "../../assets/img/sponsors/DW-Tuck-logo--new-(002).jpg";
import sponsorLogo18 from "../../assets/img/sponsors/CRS-Logo.jpg";
import sponsorLogo19 from "../../assets/img/sponsors/hi_res_KIS-Logo.jpg";
import sponsorLogo20 from "../../assets/img/sponsors/dw_tuck.jpg";
import sponsorLogo21 from "../../assets/img/sponsors/Hedbern_Homes.jpg";
import birdhouseAd from "../../assets/img/spring_tonic_birdhouse_post_v021024_1.jpg";
import birdhouseInfo from "../../assets/img/spring_tonic_birdhouse_handout_v031024_1.jpg";
import { Link, navigate } from "gatsby";
import ChatTheSeller from "./Components/ChatTheSeller/ChatTheSeller";
// import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { resetAuctionState } from "../../redux/actions/auctionActions";
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardArrowUp
} from "react-icons/md";
import { BiLoaderAlt } from "react-icons/bi";
import ToolTip from "../AC-UI-Elements/ToolTip/ToolTip";

const OFBProductPage = ({ data, storeInfo, dynamic }) => {
  console.log("data dynamic", dynamic, data);
  const { translate, langCode } = React.useContext(I18nContext);
  const location = useLocation();
  const dispatch = useDispatch();

  const [attributeJson, setAttributeJson] = useState([]);

  const [urlChecked, setUrlChecked] = useState(false);
  const [productFetchCallback, setProductFetchCallback] = useState(null);

  useEffect(() => {
    console.log("pathname", location.pathname);

    if (location.pathname.match(/\/{2,}/g)) {
      const newPath = location.pathname.replace(/\/{2,}/g, "/");
      navigate(newPath);
    }

    if (location.pathname.includes("annual-online-auction/")) {
      dispatch(unMountProductPageAction());
      navigate(
        location.pathname.replace(
          "annual-online-auction/",
          "barrie-rotary-auction/"
        )
      );
    }
    setUrlChecked(true);
    return () => {
      setUrlChecked(false);
      setProductFetchCallback(null);
    };
  }, [location.pathname]);

  useEffect(() => {
    if (productFetchCallback && urlChecked) {
      if (productFetchCallback === "404") navigate("/404/");
    }
    return () => {
      setProductFetchCallback(null);
    };
  }, [productFetchCallback, urlChecked]);

  const priceState = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );

  const hiddenPropertiesState = useSelector(
    state => state.productReducer.itemDetail.hiddenProperties,
    shallowEqual
  );

  const itemDetailState = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );

  const ItemCodeState = useSelector(
    state => state.productReducer.itemDetail.code,
    shallowEqual
  );

  const itemDetailIdState = useSelector(
    state => state.productReducer.itemDetail.itemid,
    shallowEqual
  );
  const longDescState = useSelector(
    state => state.productReducer.itemDetail.longdesc,
    shallowEqual
  );

  const IS_AUCTION = useMemo(() => {
    const staticHiddenProp =
      data &&
      data.productData._xResult[0].hiddenProperties
        .find(p => p.propname.toLowerCase() === "is_auction")
        ?.propvalue.toLowerCase() === "true";

    if (staticHiddenProp) return staticHiddenProp;
    const dynamicHiddenProp =
      hiddenPropertiesState &&
      hiddenPropertiesState
        .find(p => p.propname.toLowerCase() === "is_auction")
        ?.propvalue.toLowerCase() === "true";
    if (dynamicHiddenProp) return dynamicHiddenProp;
  }, [data, hiddenPropertiesState]);

  console.log("IS_AUCTION", IS_AUCTION);

  const swipable = useSwipeable({
    onSwipedRight: () => handlePreviousItem(),
    onSwipedLeft: () => handleNextItem(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const productUnavailableState = useSelector(
    state => state.productReducer.productUnavilable,
    shallowEqual
  );

  const breadcrumbState = useSelector(
    state => state.mainReducer.breadcrumb,
    shallowEqual
  );

  useEffect(() => {
    if (breadcrumbState && breadcrumbState.length > 1) {
      let bread = breadcrumbState[breadcrumbState.length - 1];
      let url = bread.url.replace("shop", "");
      if (!url.startsWith("/")) {
        url = "/" + url;
      }
      setCategoryURL(url);
    }
  }, [breadcrumbState]);

  const [categoryURL, setCategoryURL] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [buyBoxLoading, setBuyBoxLoading] = React.useState(true);
  const [currentCategory, setCurrentCategory] = React.useState(null);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  console.log("CURRENT INDEX ", currentIndex);

  useEffect(() => {
    if (location.pathname.includes("5k") && location.pathname.includes("race")) {
      setCurrentIndex(0);
    } else if (location.pathname.includes("10k")) {
      setCurrentIndex(1);
    } else if (location.pathname.includes("5k") && location.pathname.includes("walk")) {
      setCurrentIndex(2);
    } else if (location.pathname.includes("250m")) {
      setCurrentIndex(3);
    } else if (location.pathname.includes("corporate")) {
      setCurrentIndex(4);
    }
  }, [location.pathname, currentIndex]);

  useEffect(() => {
    setCurrentCategory(JSON.parse(localStorage.getItem("currentCategory")));
    setCurrentIndex(parseInt(localStorage.getItem("currentIndex")));
  }, []);

  useEffect(() => {
    console.info("RENDER++", ItemCodeState);
    if (typeof window !== "undefined" && ItemCodeState) {
      dispatch(getSupplierInfo(itemDetailIdState));
    }
  }, [ItemCodeState]);

  const handleBuyBoxLoading = newStatus => {
    setBuyBoxLoading(newStatus);
  };

  const handleNextItem = () => {
    dispatch(unMountProductPageAction());

    if (currentIndex == currentCategory.items.length - 1) {
      return;
    }
    localStorage.setItem("currentIndex", JSON.stringify(currentIndex + 1));
    let url = currentCategory.items[currentIndex + 1].url;
    if (url.includes("/product/")) {
      url = url.replace("/product/", "");
    } else {
      url = url.replace("product/", "");
    }
    const shouldIncludeSlash = url => {
      if (url.charAt(0) == "/") {
        return "";
      } else {
        return "/";
      }
    };

    navigate(`${shouldIncludeSlash(url)}${url}`);
  };

  const handlePreviousItem = () => {
    dispatch(unMountProductPageAction());

    if (currentIndex == 0) {
      return;
    }

    localStorage.setItem("currentIndex", currentIndex - 1);

    let url = currentCategory.items[currentIndex - 1].url;
    if (url.includes("/product/")) {
      url = url.replace("/product/", "");
    } else {
      url = url.replace("product/", "");
    }
    const shouldIncludeSlash = url => {
      if (url.charAt(0) == "/") {
        return "";
      } else {
        return "/";
      }
    };

    navigate(`${shouldIncludeSlash(url)}${url}`);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "share-popover" : undefined;

  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );

  const [swipeLoc, setSwipeLoc] = React.useState({ start: 0, end: 0 });

  const reviewsContainerRef = useRef();

  try {
    let price = data.priceInvData._xResult[0].prices[0].price_1;
  } catch (error) {
    console.error(
      "*************ERROR PRODUCT PRICE DOES NOT EXIST*************",
      data && data._0.id
    );
  }
  //console.info("borop data", data, storeInfo);

  const [count, setCount] = useState(0);

  const [starFilter, setStarFilter] = useState([]);
  const [avgRating, setAvgRating] = useState({
    avgRating: 0,
    countOfEachStar: {},
    totalValue: 0
  });

  const [reviewsWithImages, setReviewsWithImages] = useState([]);

  useEffect(() => {
    dispatch(setGoBackToCategoryFromProductPage(true));

    return () => {
      dispatch(setGoBackToCategoryFromProductPage(false));
    };
  }, []);

  console.log({ currentCategory });
  /* useLayoutEffect(() => {
    console.info("PRODINDEX 222", data._0.breadcrumbs);
    const { left, right } = mapProductUrls(
      location.pathname,
      data._0.breadcrumbs[data._0.breadcrumbs.length - 1].url.replace(
        "shop/",
        ""
      )
    );
    if (typeof window !== undefined) {
      let start = 0;
      let end = 0;
      let startY = 0;
      let endY = 0;
      let touchTime = 0;
      let diff = 0;

      const touchStartEvent = e => {
        console.error("HELLO 1");
        if (e.changedTouches[0]) {
          start = e.changedTouches[0].screenX;
          startY = e.changedTouches[0].screenY;
          touchTime = new Date();
        }
      };

      const touchEndEvent = e => {
        console.error("HELLO 2");
        if (e.changedTouches[0]) {
          end = e.changedTouches[0].screenX;
          endY = e.changedTouches[0].screenY;
          diff = new Date() - touchTime;
          handleSwiper();
        }
      };

      const handleSwiper = () => {
        if (Math.abs(end - start) > Math.abs(endY - startY) && diff > 100) {
          if (end < start) {
            navigate(`/${left}`, { state: { directionCat: "left" } });
          } else if (end > start) {
            navigate(`/${right}`, { state: { directionCat: "right" } });
          }
        }
      };

      window.addEventListener("touchstart", touchStartEvent);

      window.addEventListener("touchend", touchEndEvent);

      return () => {
        window.removeEventListener("touchstart", touchStartEvent);
        window.removeEventListener("touchend", touchEndEvent);
      };
    }
  }, []);
 */

  const longDesc = useMemo(() => {
    let tempDesc;
    if (data) {
      tempDesc = {
        longdesc: data.productData._xResult[0].longdesc,
        longdesc2: data.productData._xResult[0].longdesc2,
        longdesc3: data.productData._xResult[0].longdesc3,
        longdesc4: data.productData._xResult[0].longdesc4
      };
    }
    return tempDesc;
  }, [data]);
  const { longdesc3, longdesc4 } = itemDetailState;
  console.log("longDesc", longDesc);
  useEffect(() => {
    if (count == 0 && typeof localStorage !== undefined) {
      setCount(count + 1);
      let languageStorage = localStorage.getItem("language");
      if (!languageStorage) {
        localStorage.setItem("language", langCode);
        languageStorage = "en";
      }
      let countryStorage = localStorage.getItem("country");
      if (!countryStorage) {
        countryStorage = "en";
        localStorage.setItem("country", countryStorage);
      }
      let url = location.pathname;
      if (url[url.length - 1] === "/") url = url.substr(0, url.length - 1);

      if (url.includes(`/${localStorage.getItem("language")}/`) === false) {
        dispatch(
          fetchDirectUrlGetItem(
            url,
            localStorage.getItem("language"),
            countryStorage,
            setProductFetchCallback
          )
        );
      } else {
        setCount(count + 1);
        dispatch(
          fetchDirectUrlGetItem(
            url,
            langCode,
            countryStorage,
            setProductFetchCallback
          )
        );
      }
    }

    return () => {
      setCount(0);
    };
  }, [location.pathname, langCode]);

  useEffect(() => {
    if (
      document.getElementById("pickupShipInfo") != null &&
      data &&
      !!IS_AUCTION
    ) {
      var cartBox = document.getElementById("pickupShipInfo");
      cartBox.innerHTML = "";
      var tempPickupHTML = "";
      var tempShipHTML = "";

      // if (!data.url.includes("ice-fishing-raffle")) {
      if (
        data.productData._xResult[0].hiddenProperties[3].propvalue !== "true"
      ) {
        if (storeInfo.pickupLocData && storeInfo.pickupLocData) {
          tempPickupHTML =
            "<div class='pickupShipInfo'><h2>Pickup Locations</h2><div class='pickupShipInfoBody pickup'>";
          if (!isArray(storeInfo.pickupLocData))
            storeInfo.pickupLocData = [{ ...storeInfo.pickupLocData }];
          for (var x = 0; x < storeInfo.pickupLocData.length; x++) {
            tempPickupHTML =
              tempPickupHTML +
              "<p class='pickupDataBox'><strong>" +
              storeInfo.pickupLocData[x].pickup_location_name +
              "</strong><br/>" +
              storeInfo.pickupLocData[x].address_place +
              " <br/> <a target='_blank' href='https://www.google.com/maps/dir/here/" +
              storeInfo.pickupLocData[x].latitude +
              "," +
              storeInfo.pickupLocData[x].longitude +
              "'>Get Directions</a>";

            if (storeInfo.pickupLocData[x].time != null) {
              tempPickupHTML =
                tempPickupHTML +
                "<br/><strong>Hours:</strong> " +
                storeInfo.pickupLocData[x].time;
            }

            if (storeInfo.pickupLocData[x].additional_information != null) {
              tempPickupHTML =
                tempPickupHTML +
                "<br/><strong>Additional Info:</strong> " +
                storeInfo.pickupLocData[x].additional_information;
            }

            tempPickupHTML = tempPickupHTML + "</p>";
          }

          tempPickupHTML = tempPickupHTML + "</div></div>";
        }
      }

      if (
        storeInfo.supplierShippingInfo &&
        storeInfo.supplierShippingInfo.length > 0 &&
        storeInfo.supplierShippingInfo[0].shipping_type != 0 &&
        storeInfo.supplierShippingInfo[0].shipping_type != null
      ) {
        tempShipHTML =
          "<div class='pickupShipInfo'><h2>Shipping Info</h2><div class='pickupShipInfoBody'>";

        var tempShipHTML = tempShipHTML + "<p><strong>Ships:</strong> ";

        if (storeInfo.supplierShippingInfo[0].shipping_type == 1) {
          tempShipHTML = tempShipHTML + "Worldwide";
        } else if (storeInfo.supplierShippingInfo[0].shipping_type == 2) {
          tempShipHTML = tempShipHTML + "Within Canada";
        } else if (storeInfo.supplierShippingInfo[0].shipping_type == 3) {
          if (storeInfo.storeSellerData.province) {
            tempShipHTML =
              tempShipHTML + "Within " + storeInfo.storeSellerData.province;
          } else {
            tempShipHTML = tempShipHTML + "Within Province";
          }
        } else if (storeInfo.supplierShippingInfo[0].shipping_type == 4) {
          tempShipHTML =
            tempShipHTML +
            "Within " +
            storeInfo.supplierShippingInfo[0].range +
            "KM";
        }

        tempShipHTML = tempShipHTML + "</p>";

        if (
          storeInfo.supplierShippingInfo[0].instructions &&
          storeInfo.supplierShippingInfo[0].instructions != null
        ) {
          tempShipHTML =
            tempShipHTML +
            "<p><strong>Additional Info:</strong> " +
            storeInfo.supplierShippingInfo[0].instructions +
            "</p>";
        }

        tempShipHTML = tempShipHTML + "</div></div>";
      }

      cartBox.innerHTML = cartBox.innerHTML + tempPickupHTML;
      cartBox.innerHTML = cartBox.innerHTML + tempShipHTML;
    }
  }, []);

  /*let imageUrl = storeInfo.storeSellerData.image;
  if (imageUrl.includes("sls3.avetti.ca")) {
    imageUrl = imageUrl.replace(
      "sls3.avetti.ca",
      "demob2b2cs3.avetti.ca/store"
    );
  }*/

  var mapsLink;
  if (
    storeInfo &&
    storeInfo.storeSellerData &&
    Object.keys(storeInfo.storeSellerData).includes("location") &&
    storeInfo.storeSellerData.location
  ) {
    mapsLink =
      "https://www.google.ca/maps/search/" + storeInfo.storeSellerData.location;
  } else {
    mapsLink = "";
  }

  useEffect(() => {
    if (typeof window !== undefined)
      document.body.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <React.Fragment>
      {/*  <EnquiryModal
        isLoggedIn={loginNameState !== ""}
        enquiryModalState={enquiryModalState}
        setEnquiryModalState={setEnquiryModalState}
      /> */}
      {/* <AccessoryModal /> */}

      <div id="bd" className="item">
        {/*<h1>Test</h1>*/}
        <div className="col s12">
          <div style={{ marginTop: "5px" }}>
            <div
              className={`item-main${data?.productData._xResult[0].code === "AINAOXAEG2"
                ? " springTonicItem"
                : ""
                }`}
            >
              <div className="item-top">
                {/*<Breadcrumb back={true} />*/}
                {/*{currentCategory && (*/}
                {/*  <div className="swipe-from-products">*/}
                {/*    /!*{currentIndex !== 0 && (*!/*/}
                {/*    /!*  <ToolTip title="Previous Product" placement="top">*!/*/}
                {/*    /!*    <button className="items-nav-btn" onClick={handlePreviousItem}>*!/*/}
                {/*    /!*      <span className="material-icons">*!/*/}
                {/*    /!*        <MdKeyboardArrowLeft />*!/*/}
                {/*    /!*      </span>*!/*/}
                {/*    /!*    </button>*!/*/}
                {/*    /!*  </ToolTip>*!/*/}
                {/*    /!*)}*!/*/}
                {/*    /!*{currentCategory &&*!/*/}
                {/*    /!*currentCategory.items &&*!/*/}
                {/*    /!*currentIndex !== currentCategory.items.length - 1 && (*!/*/}
                {/*    /!*  <ToolTip title="Next Product" placement="top">*!/*/}
                {/*    /!*    <button className="items-nav-btn" onClick={handleNextItem}>*!/*/}
                {/*    /!*      <span className="material-icons">*!/*/}
                {/*    /!*        <MdKeyboardArrowRight />*!/*/}
                {/*    /!*      </span>*!/*/}
                {/*    /!*    </button>*!/*/}
                {/*    /!*  </ToolTip>*!/*/}
                {/*    /!*)}*!/*/}
                {/*    <ToolTip title="Back to Category Page" placement="top">*/}
                {/*      <button*/}
                {/*        className="items-nav-btn"*/}
                {/*        onClick={() => {*/}
                {/*          dispatch(backToCategory());*/}
                {/*          navigate(`/${currentCategory.url}/`, {*/}
                {/*            state: {*/}
                {/*              itemId: itemDetailIdState*/}
                {/*            }*/}
                {/*          });*/}
                {/*        }}*/}
                {/*      >*/}
                {/*        <span className="material-icons">*/}
                {/*          <MdKeyboardArrowLeft />*/}
                {/*        </span>*/}
                {/*      </button>*/}
                {/*    </ToolTip>*/}
                {/*  </div>*/}
                {/*)}*/}
              </div>
              <div id="mainGridDiv" className="item-top-row row">
                {isMobileState ? (
                  <React.Fragment>
                    <p className="brandItem">
                      <span id="buyBoxDistName">
                        {data &&
                          data.supplierData &&
                          data.supplierData._xResult &&
                          data.supplierData._xResult.length > 0 &&
                          data.supplierData._xResult[0].distributorOrder &&
                          data.supplierData._xResult[0].distributorOrder.length >
                          0 &&
                          data.supplierData._xResult[0].distributorOrder[0].name
                          ? data.supplierData._xResult[0].distributorOrder[0]
                            .name
                          : ""}
                      </span>
                    </p>

                    <ReviewBarMobile
                      avgRating={avgRating}
                      starFilter={starFilter}
                      setStarFilter={setStarFilter}
                      reviewsContainerRef={reviewsContainerRef}
                    />

                    <div
                      id="js-item-title-267578"
                      className="regularTitle title itemTitle itemTitleMobile"
                      dangerouslySetInnerHTML={{
                        __html: htmldecoder(
                          data
                            ? data.productData._xResult[0].title
                            : itemDetailState.title
                        )
                      }}
                    ></div>
                  </React.Fragment>
                ) : null}

                <div id="leftGrid">
                  <ImageCarousel
                    productUnavailable={productUnavailableState}
                    giftcard="false"
                    data={{
                      productCode: data
                        ? data.productData._xResult[0].code
                        : itemDetailState.code,
                      itemId: data ? data._0.id : itemDetailState.itemId,
                      hiddenProperties: data
                        ? data.productData._xResult[0].hiddenProperties
                        : itemDetailState.hiddenProperties,
                      title: data
                        ? data.productData._xResult[0].title
                        : itemDetailState.title,
                      url: data ? data.url : ""
                    }}
                    title={
                      data
                        ? data.productData._xResult[0].title
                        : itemDetailState.title
                    }
                    imageUrl={`https://preview.open4business.io/preview/store/20180522154/assets/items/images/${data
                      ? data.productData._xResult[0].code
                      : itemDetailState.code
                      }.jpg`}
                  />
                  {/*<div className="share-buttons">*/}
                  {/*  <ShareButtons*/}
                  {/*    title={*/}
                  {/*      data*/}
                  {/*        ? data.productData._xResult[0].title*/}
                  {/*        : itemDetailState.title*/}
                  {/*    }*/}
                  {/*    imageUrl={`https://preview.open4business.io/preview/store/20180522154/assets/items/images/${data*/}
                  {/*      ? data.productData._xResult[0].code*/}
                  {/*      : itemDetailState.code*/}
                  {/*      }.jpg`}*/}
                  {/*  />*/}
                  {/*</div>*/}
                </div>
                <div id="rightGrid">
                  <div id="titleGrid" style={{ marginTop: "30px" }}>
                    {!isMobileState ? (
                      <React.Fragment>
                        <div
                          id="js-item-title-267578"
                          className="regularTitle title itemTitle itemTitleMobile"
                          dangerouslySetInnerHTML={{
                            __html: htmldecoder(
                              data
                                ? data.productData._xResult[0].title
                                : itemDetailState.title
                            )
                          }}
                        />
                      </React.Fragment>
                    ) : null}
                    {/* {!isMobileState ? ( */}
                    <React.Fragment>
                      {/*<ReviewBar*/}
                      {/*  avgRating={avgRating}*/}
                      {/*  starFilter={starFilter}*/}
                      {/*  setStarFilter={setStarFilter}*/}
                      {/*  reviewsContainerRef={reviewsContainerRef}*/}
                      {/*/>*/}
                      <WishListBar
                        data={data}
                        productUnavailable={productUnavailableState}
                        price={
                          data
                            ? data.priceInvData._xResult[0].prices[0].price_1
                            : priceState.prices && priceState.prices[0].price
                        }
                      />
                    </React.Fragment>
                    {/* // ) : null} */}
                    {/*<ItemCode*/}
                    {/*  code={*/}
                    {/*    data ? data.productData._xResult[0].code : ItemCodeState*/}
                    {/*  }*/}
                    {/*/>*/}
                    <div className="col-xs-12 product-page-seller-info">
                      {!isMobileState ? (
                        <React.Fragment>
                          {/* <div className="inStock-container">
                            {priceState && priceState.invs && priceState.invs[0] ? (
                              <div className="inStock-wrapper">
                                <label>{translate("js.item.stock")}:</label>
                                &nbsp;
                                <span style={{ fontWeight: "400" }}>
                                  {priceState.invs[0].instock}
                                </span>
                              </div>
                            ) : null}
                          </div> */}

                          {/*<p>*/}
                          {/*  <strong>{translate("js.item.available")}: </strong>*/}
                          {/*  <span*/}
                          {/*    id="buyBoxDistName"*/}
                          {/*    style={{ fontWeight: "400" }}*/}
                          {/*  >*/}
                          {/*    /!* {data &&*/}
                          {/*    data.supplierData &&*/}
                          {/*    data.supplierData._xResult &&*/}
                          {/*    data.supplierData._xResult.length > 0 &&*/}
                          {/*    data.supplierData._xResult[0].distributorOrder &&*/}
                          {/*    data.supplierData._xResult[0].distributorOrder*/}
                          {/*      .length > 0 &&*/}
                          {/*    data.supplierData._xResult[0].distributorOrder[0]*/}
                          {/*      .name*/}
                          {/*      ? data.supplierData._xResult[0]*/}
                          {/*          .distributorOrder[0].name*/}
                          {/*      : ""} *!/*/}
                          {/*    {longdesc3}*/}
                          {/*  </span>*/}
                          {/*</p>*/}
                        </React.Fragment>
                      ) : null}
                      <p>
                        <strong>{translate("js.item.location")}: </strong>
                        <span id="buyBoxDistLoc" style={{ fontWeight: "400" }}>
                          {data?.productData._xResult[0].code === "AIN6XF2UT2"
                            ? "Sadlon Arena, 555 Bayview Dr, Barrie"
                            : data &&
                              data.supplierData &&
                              data.supplierData._xResult &&
                              data.supplierData._xResult.length > 0 &&
                              data.supplierData._xResult[0].distributorOrder &&
                              data.supplierData._xResult[0].distributorOrder
                                .length > 0 &&
                              data.supplierData._xResult[0].distributorOrder[0]
                                .city
                              ? data.supplierData._xResult[0].distributorOrder[0]
                                .city
                              : ""}
                        </span>
                      </p>

                      {/*<div className="product-page-seller-rating">
                        <strong>
                          {translate("js.item.sellerrating")}:&nbsp;
                        </strong>
                        <div id="buyBoxDistRate">
                          <div className="dist-item-rating">
                            <i className="material-icons star-icon">
                              <MdStarBorder />
                            </i>
                            <i className="material-icons star-icon">
                              <MdStarBorder />
                            </i>
                            <i className="material-icons star-icon">
                              <MdStarBorder />
                            </i>
                            <i className="material-icons star-icon">
                              <MdStarBorder />
                            </i>
                            <i className="material-icons star-icon">
                              <MdStarBorder />
                            </i>
                            <div
                              style={{ display: "none" }}
                              className="distReviewCount"
                            >
                              0 {translate("js.item.reviews")}
                            </div>
                          </div>
                          </div>
                      </div>*/}
                    </div>

                    <div id="loadIcon">
                      <BiLoaderAlt className="spin" />
                    </div>

                    <Attributes attributeJson={attributeJson} setAttributeJson={setAttributeJson} />

                    {/*                      <Marketplaces />*/}
                    {
                      <div includes="description">
                        {/*<OtherInfoTab*/}
                        {/*  longDesc={{*/}
                        {/*    longdesc: data.productData._xResult[0].longdesc,*/}
                        {/*    longdesc2: data.productData._xResult[0].longdesc2,*/}
                        {/*    longdesc3: data.productData._xResult[0].longdesc3,*/}
                        {/*    longdesc4: data.productData._xResult[0].longdesc4*/}
                        {/*  }}*/}
                        {/*  properties={data.productData._xResult[0].properties}*/}
                        {/*/>*/}
                      </div>
                    }
                  </div>
                  <CheckBoxAddons />
                  {data &&
                    data.productData._xResult[0].hiddenProperties.filter(
                      l => l.propname === "IS_PRIZE"
                    )[0]?.propvalue !== "true" ? (
                    <AddToCartBox
                      data={data}
                      IS_AUCTION={IS_AUCTION}
                      productUnavailable={productUnavailableState}
                      giftcard="false"
                      priceInv={data.priceInvData._xResult[0]}
                      supplierData={
                        data.supplierData !== null &&
                          Object.keys(data.supplierData).includes("_xResult") &&
                          data.supplierData._xResult.length > 0
                          ? data.supplierData._xResult[0]
                          : null
                      }
                      storeInfo={storeInfo}
                      title={htmlDecode(
                        data
                          ? data.productData._xResult[0].code
                          : itemDetailState.code
                      )}
                    />
                  ) : (
                    <ChatTheSeller
                      storeSellerData={storeInfo?.storeSellerData}
                    />
                  )}
                </div>
                <ConfirmDeliveryOptions />
                <CartValidationErrors />
                <OutOfStockError />
                <AddedToCartModal
                  attributeJson={attributeJson}
                  setAttributeJson={setAttributeJson} />
              </div>
            </div>
          </div>
        </div>
        {/* <Accessories /> */}
        {/* <VendorList
          handleEnquiryModalOpenClicked={handleEnquiryModalOpenClicked}
        /> */}

        <div className="clearfix"></div>

        <div class="row">
          {data &&
            data.productData._xResult[0].hiddenProperties.filter(
              l => l.propname === "IS_PRIZE"
            )[0]?.propvalue !== "true" ? (
            <div
              class="bottomInfo"
              style={
                data.productData._xResult[0].hiddenProperties.filter(
                  l => l.propname === "entity_type"
                )[0]?.propvalue === "ticket"
                  ? {
                    gridTemplateColumns: "100%"
                  }
                  : null
              }
            >
              {data.productData._xResult[0].hiddenProperties.filter(
                l => l.propname === "entity_type"
              )[0]?.propvalue !== "ticket" &&
                (data?.productData._xResult[0].code == "AINJV1BAC2" ||
                  data?.productData._xResult[0].code == "AIN1JVHBC2" ||
                  data?.productData._xResult[0].code == "AIN7RWYVC0" ||
                  data?.productData._xResult[0].code == "AINWE1P372" ||
                  data?.productData._xResult[0].code == "AINDU4UV82" ||
                  data?.productData._xResult[0].code == "AINUHSNWF0" ||
                  data?.productData._xResult[0].code == "AINNFZUK50") && (
                  <div id="birdhouseContest">
                    {data?.productData._xResult[0].code == "AIN7RWYVC0" ? (
                      <a href="/store/20200929200/assets/spring_tonic_birdhouse_handout_v03.pdf">
                        <img className="img-responsive" src={birdhouseInfo} />
                      </a>
                    ) : (
                      <Link to="/spring-tonic/bird-boxes-ain7rwyvc0/">
                        <img className="img-responsive" src={birdhouseAd} />
                      </Link>
                    )}

                    {data?.productData._xResult[0].code == "AIN7RWYVC0" ? (
                      <a
                        href="https://s3.ca-central-1.amazonaws.com/ofb11images.avetti.ca/store/20200929200/assets/Bird+Box+Assembly+Instructions_2021.pdf">
                        <button>
                          Click to Download the Assembly Instructions
                        </button>
                      </a>
                    ) : (
                      <Link to="/spring-tonic/bird-boxes-ain7rwyvc0/">
                        <button>Buy Your Birdhouse Project Kit Here</button>
                      </Link>
                    )}
                  </div>
                )}

              {data.productData._xResult[0].hiddenProperties.filter(
                l => l.propname === "entity_type"
              )[0]?.propvalue !== "ticket" &&
                data?.productData._xResult[0].code != "AINWG5PKC2" &&
                data?.productData._xResult[0].code != "AIN5KCEDB0" &&
                data?.productData._xResult[0].code != "AIN6XF2UT2" &&
                (
                  <div includes="description">
                    <OtherInfoTab
                      longDesc={longDesc && longDesc}
                      properties={data.productData._xResult[0].properties}
                      data={data}
                    />
                  </div>
                )}

              {data.productData._xResult[0].hiddenProperties.filter(
                l => l.propname === "entity_type"
              )[0]?.propvalue !== "ticket" &&
                (data?.productData._xResult[0].code == "AIN6XF2UT2") && (
                  <div id="description" className="hockeyDesc">
                    <h2>Featuring:</h2>
                    <div id="hockeyPlayersGrid">
                      <div id="player1">
                        <img src="https://ik.imagekit.io/ofb/RT/448795121_18093442384439953_3084837573990354768_n_9nC8uJKdX.jpg?updatedAt=1720480603777" />
                      </div>
                      <div id="player2">
                        <img src="https://ik.imagekit.io/ofb/RT/450127893_18094862788439953_4970079589245351092_n_r4dCsxudt.jpg?updatedAt=1720480603097" />
                      </div>
                      <div id="player3">
                        <img src="https://ik.imagekit.io/ofb/RT/448913129_18093544492439953_5162995935313157625_n_bO139cFnT.jpg?updatedAt=1720480602304" />
                      </div>
                      <div id="player4">
                        <img src="https://ik.imagekit.io/ofb/RT/448846813_18093934159439953_5292629928574579402_n_ss2a7b8hN.jpg?updatedAt=1720480602544" />
                      </div>
                      <div id="player5">
                        <img src="https://ik.imagekit.io/ofb/RT/449690599_18094616707439953_2285747871067343435_n_l7uDrVmrq.jpg?updatedAt=1720480602581" />
                      </div>
                    </div>

                    <div className="hockeyDesc">
                      <h3>The Barn Burner hockey game, which has hosted the NHL's top talent will be returning to the Sadlon Arena in Barrie on August 7th.</h3>
                      <p>Experience the thrill of live hockey at Sadlon Arena on August 7th! Witness some of your favorite NHL and PWHL stars up close, including <strong>John Tavares, Andrew Mangiapane, Jill Saulnier, Quinton Byfield, and Devin Shore</strong>. Don't miss this incredible event—support a great cause and be part of the excitement. Get your tickets now!</p>
                      <p><strong>When</strong>: Wednesday, August 7, 2024</p>
                      <ul>
                        <li><strong>Doors open:</strong> 5:30 p.m.</li>
                        <li><strong>Ceremony Program start:</strong> 6:30 p.m.</li>
                        <li><strong>Puck Drops:</strong> 7:00 p.m.</li>
                      </ul>
                      <p><strong>Where</strong>: Sadlon Arena, 555 Bayview Dr, Barrie</p>
                      <p><strong>Cost</strong>: $25.00 per ticket</p>
                    </div>

                    <h2>Supporting:</h2>

                    <p style={{textAlign: "center", fontSize: "1.5em"}}>Royal Victoria Health Centre, Rotary, PIE Education, Easter Seals and CMHA in Barrie</p>
                  </div>
                )}

              {data.productData._xResult[0].hiddenProperties.filter(
                l => l.propname === "entity_type"
              )[0]?.propvalue !== "ticket" &&
                (data?.productData._xResult[0].code == "AINJV1BAC2" ||
                  data?.productData._xResult[0].code == "AIN1JVHBC2" ||
                  data?.productData._xResult[0].code == "AIN7RWYVC0" ||
                  data?.productData._xResult[0].code == "AINWE1P372" ||
                  data?.productData._xResult[0].code == "AINDU4UV82" ||
                  data?.productData._xResult[0].code == "AINUHSNWF0" ||
                  data?.productData._xResult[0].code == "AINNFZUK50") && (
                  <div id="sponsors">
                    <div class="row">
                      <h3>Thank You To Our Sponsors</h3>
                    </div>
                    <div className="sponsorGrid">
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo1} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo2} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo3} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo4} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo5} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo6} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo7} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo8} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo9} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo10} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo11} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo12} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo13} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo14} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo15} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo16} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo17} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo18} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo19} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo20} />
                      </div>
                      <div className="sponsorImg">
                        <img className="img-responsive" src={sponsorLogo21} />
                      </div>
                    </div>
                  </div>
                )}
            </div>
          ) : (
            <div className="row">
              <div id="birdhouseContest">
                <div includes="description">
                  <div
                    className="col-xs-12 product-details-specs-container"
                    style={{ backgroundColor: "white" }}
                  >
                    <div className="product-details-specs-wrapper">
                      <div className="product-details-wrapper">
                        {/*<h2>{translate("js.item.productdetails")}</h2>*/}
                        <div id="description" className="otherDesktop">
                          {longDesc ? (
                            Object.keys(longDesc).map((desc, index) => {
                              if (longDesc[desc] !== "") {
                                return (
                                  <div
                                    // style={{
                                    //   fontFamily: "Quicksand",
                                    //   fontSize: "14px",
                                    //   textAlign: "left",
                                    //   fontWeight: "400"
                                    // }}
                                    key={index}
                                    dangerouslySetInnerHTML={{
                                      __html: htmldecoder(longDesc[desc])
                                    }}
                                  />
                                );
                              }
                            })
                          ) : (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: htmldecoder(longDescState)
                              }}
                            />
                          )}
                          {/* <h3
                            style={{ backgroundColor: "white" }}
                            dangerouslySetInnerHTML={{
                              __html:
                                data && data.productData._xResult[0].longdesc
                            }}
                          ></h3>
                          <p>
                            {data && data.productData._xResult[0].longdesc2}
                          </p>
                          <h1 style={{ marginTop: "30px" }}>
                            {data && data.productData._xResult[0].longdesc3}
                          </h1>
                          <p>
                            {data && data.productData._xResult[0].longdesc4}
                          </p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="sponsors">
                    <h3>Thank You To Our Sponsor</h3>
                    <div className="sponsorImg">
                      <img
                        style={{ width: "200px" }}
                        className="img-responsive"
                        src={`https://ik.imagekit.io/ofb/store/20180522154/assets/items/largeimages/${data
                          ? data.productData._xResult[0].code
                          : ItemCodeState
                          }-2.jpg`}
                      />
                      <a
                        href={`${data &&
                          data.productData._xResult[0].hiddenProperties.find(
                            l => l.propname === "Website"
                          )?.propvalue
                          }`}
                        target="_blank"
                      >
                        <button>Learn More About Our Sponsor!</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/*{storeInfo.storeSellerData && (
          <div className="storeInfoWrapper itemStoreInfo">
            <div className="storeLogo"><img src={imageUrl}></img></div>
            <div className="storeInfoMain">
              <div className="storeInfo1">
                <h1 className="storeName testClass">
                  {storeInfo.storeSellerData && storeInfo.storeSellerData.brand}
                </h1>
                {storeInfo.storeSellerData &&
                Object.keys(storeInfo.storeSellerData).includes("year") &&
                storeInfo.storeSellerData.year != "" ? (
                  <p>
                    <strong>
                      Established{" "}
                      {storeInfo.storeSellerData &&
                        Object.keys(storeInfo.storeSellerData).includes(
                          "year"
                        ) &&
                        storeInfo.storeSellerData.year}{" "}
                      -{" "}
                      {storeInfo.storeSellerData &&
                        Object.keys(storeInfo.storeSellerData).includes(
                          "city"
                        ) &&
                        storeInfo.storeSellerData.city}
                      ,{" "}
                      {storeInfo.storeSellerData &&
                        Object.keys(storeInfo.storeSellerData).includes(
                          "province"
                        ) &&
                        storeInfo.storeSellerData.province}
                    </strong>
                  </p>
                ) : null}

                {storeInfo.storeSellerData &&
                Object.keys(storeInfo.storeSellerData).includes(
                  "phone_number"
                ) &&
                storeInfo.storeSellerData.phone_number != "" ? (
                  <p>
                    <strong>Phone:</strong>{" "}
                    {storeInfo.storeSellerData &&
                      Object.keys(storeInfo.storeSellerData).includes(
                        "phone_number"
                      ) &&
                      storeInfo.storeSellerData.phone_number}
                  </p>
                ) : null}

                {storeInfo.storeSellerData &&
                Object.keys(storeInfo.storeSellerData).includes("location") &&
                storeInfo.storeSellerData.location != "" ? (
                  <p>
                    <strong>Address:</strong>{" "}
                    {storeInfo.storeSellerData &&
                      Object.keys(storeInfo.storeSellerData).includes(
                        "location"
                      ) &&
                      storeInfo.storeSellerData.location}
                    <br />
                    {mapsLink != "" ? (
                      <a className="mapsLink" target="_blank" href={mapsLink}>
                        View on Google Maps
                      </a>
                    ) : null}
                  </p>
                ) : null}

                {storeInfo.storeSellerData &&
                Object.keys(storeInfo.storeSellerData).includes("website") &&
                storeInfo.storeSellerData.website != null ? (
                  <p>
                    <strong>Website:</strong>{" "}
                    {storeInfo.storeSellerData.website}
                  </p>
                ) : null}
                <p className="sellerSocialLine">
                  {storeInfo.storeSellerData !== undefined &&
                  (Object.keys(storeInfo.storeSellerData).includes(
                    "facebook"
                  ) ||
                    Object.keys(storeInfo.storeSellerData).includes(
                      "twitter"
                    ) ||
                    Object.keys(storeInfo.storeSellerData).includes(
                      "instagram"
                    ) ||
                    Object.keys(storeInfo.storeSellerData).includes(
                      "pinterest"
                    )) &&
                  (storeInfo.storeSellerData.facebook != null ||
                    storeInfo.storeSellerData.twitter != null ||
                    storeInfo.storeSellerData.instagram != null ||
                    storeInfo.storeSellerData.pinterest != null) ? (
                    <span>
                      <strong>Connect With Us:</strong>
                      <br />
                    </span>
                  ) : null}

                  {storeInfo.storeSellerData &&
                  Object.keys(storeInfo.storeSellerData).includes("facebook") &&
                  storeInfo.storeSellerData.facebook != null ? (
                    <a href={storeInfo.storeSellerData.facebook}>
                      <img className="sellerSocialLogo" src={fbLogo} />
                    </a>
                  ) : null}
                  {storeInfo.storeSellerData &&
                  Object.keys(storeInfo.storeSellerData).includes("twitter") &&
                  storeInfo.storeSellerData.twitter != null ? (
                    <a href={storeInfo.storeSellerData.twitter}>
                      <img className="sellerSocialLogo" src={twLogo} />
                    </a>
                  ) : null}
                  {storeInfo.storeSellerData &&
                  Object.keys(storeInfo.storeSellerData).includes(
                    "instagram"
                  ) &&
                  storeInfo.storeSellerData.instagram != null ? (
                    <a href={storeInfo.storeSellerData.instagram}>
                      <img className="sellerSocialLogo" src={igLogo} />
                    </a>
                  ) : null}
                  {storeInfo.storeSellerData &&
                  Object.keys(storeInfo.storeSellerData).includes(
                    "pinterest"
                  ) &&
                  storeInfo.storeSellerData.pinterest != null ? (
                    <a href={storeInfo.storeSellerData.pinterest}>
                      <img className="sellerSocialLogo" src={ptLogo} />
                    </a>
                  ) : null}
                </p>
              </div>
              <div className="storeInfo2">
                <h2 className="storeName">About Us</h2>

                <div id="aboutInfo">
                  <p>
                    {storeInfo.storeSellerData &&
                      storeInfo.storeSellerData.description &&
                      storeInfo.storeSellerData.description.blocks &&
                      storeInfo.storeSellerData.description.blocks[0] &&
                      storeInfo.storeSellerData.description.blocks[0].text}
                  </p>
                </div>
              </div>
            </div>
                    </div>
        )}*/}
        <Reviews
          avgRating={avgRating}
          setAvgRating={setAvgRating}
          starFilter={starFilter}
          setStarFilter={setStarFilter}
          reviewsContainerRef={reviewsContainerRef}
          reviewsWithImages={reviewsWithImages}
          setReviewsWithImages={setReviewsWithImages}
        />
        {/* <RecentlyViewedItems /> */}
      </div>
    </React.Fragment>
  );
};

export default OFBProductPage;
