import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";

import htmldecoder from "../../../../functions/htmldecoder";

import { I18nContext } from "../../../../i18n/index";
import "./Styles/OtherInfoTab.css";

const OtherInfoTab = ({ longDesc, properties, data }) => {
  const { translate } = React.useContext(I18nContext);

  const longDescState = useSelector(
    state => state.productReducer.itemDetail.longdesc,
    shallowEqual
  );

  const itemDetailState = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );

  const { longdesc, longdesc2, longdesc3, longdesc4 } =
    itemDetailState || longDesc;

  console.log(data.productData._xResult[0]);

  return (
    <div
      className="col-xs-12 product-details-specs-container"
      style={{ backgroundColor: "white" }}
    >
      <div className="product-details-specs-wrapper">
        <div className="product-details-wrapper">
          {/*<h2>{translate("js.item.productdetails")}</h2>*/}
          <div id="description" className="otherDesktop">
            <h3 style={{ backgroundColor: "white" }}>
              {data.productData._xResult[0].title}
            </h3>
            {longDesc && longDesc != "" ? (
              <div
                style={{
                  fontFamily: "Quicksand",
                  fontSize: "1.15em",
                  textAlign: "left",
                  fontWeight: "400",
                  lineHeight: "25px",
                  letterSpacing: "0"
                }}
                dangerouslySetInnerHTML={{
                  __html: htmldecoder(longdesc)
                }}
              />
            ) : (
              <p
                dangerouslySetInnerHTML={{
                  __html: htmldecoder(longDescState)
                }}
              />
            )}
            {data &&
            data.productData._xResult[0].hiddenProperties.filter(
              l => l.propname === "Sponsor"
            )[0]?.propvalue !== undefined ? (
              <>
                <h3 style={{ marginTop: "30px" }}>Thank You To Our Sponsor</h3>
                <h4
                  style={{ marginTop: "30px" }}
                  dangerouslySetInnerHTML={{
                    __html: htmldecoder(longdesc3)
                  }}
                ></h4>
                <p
                  dangerouslySetInnerHTML={{
                    __html: htmldecoder(longdesc4)
                  }}
                />
                <div id="sponsors">
                  <div className="sponsorImg">
                    {data &&
                    data.productData._xResult[0].hiddenProperties.filter(
                      l => l.propname === "sponsor_image"
                    )[0]?.propvalue !== undefined ? (
                      <img
                        style={{ width: "200px" }}
                        className="img-responsive"
                        src={`https://ik.imagekit.io/ofb/store/20210927532/assets/items/largeimages/${
                          data.productData._xResult[0].hiddenProperties.find(
                            l => l.propname === "sponsor_image"
                          )?.propvalue
                        }?t=${new Date().getTime()}`}
                      />
                    ) : (
                      " "
                    )}
                    {data &&
                    data.productData._xResult[0].hiddenProperties.filter(
                      l => l.propname === "Website"
                    )[0]?.propvalue !== undefined ? (
                      <a
                        href={`${
                          data.productData._xResult[0].hiddenProperties.find(
                            l => l.propname === "Website"
                          )?.propvalue
                        }`}
                        target="_blank"
                      >
                        <button
                          style={{
                            color: "rgb(232,230,227)",
                            backgroundColor: "rgb(26,91,155)",
                            marginTop: "40px",
                            display: "block",
                            marginLeft: "auto",
                            marginRight: "auto",
                            padding: "15px 25px"
                          }}
                        >
                          Learn More About Our Sponsor!
                        </button>
                      </a>
                    ) : (
                      " "
                    )}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>

        {/*<div className="product-specs-wrapper">
          <h2>{translate("js.item.specifications")}</h2>
          <div id="Specifi">
            {properties && properties.length > 0 ? (
              <div className="specs-list">
                <table className="table table-striped">
                  <tbody>
                    {properties
                      ? properties
                          .slice(0, properties.length / 2)

                          .map(property => {
                            return (
                              <tr key={property.propname}>
                                <th>
                                  <strong>
                                    {property.propname.split("_").join(" ")}
                                  </strong>
                                </th>
                                <td>{property.propvalue}</td>
                              </tr>
                            );
                          })
                      : null}
                  </tbody>
                </table>

                <table className="table table-striped">
                  <tbody>
                    {properties
                      ? properties
                          .slice(properties.length / 2, properties.length)
                          .map(property => {
                            return (
                              <tr key={property.propname}>
                                <th>
                                  <strong>
                                    {property.propname.split("_").join(" ")}
                                  </strong>
                                </th>
                                <td>{property.propvalue}</td>
                              </tr>
                            );
                          })
                      : null}
                  </tbody>
                </table>
              </div>
            ) : (
              <h3>There is no {translate("js.item.specifications")} to show</h3>
            )}
            <div className="clear"></div>
          </div>
            </div>*/}
      </div>
    </div>
  );
};

export default OtherInfoTab;
