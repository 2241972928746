import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  TwitterShareButton,
  FacebookShareButton,
  EmailShareButton,
  LineShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  WhatsappShareButton
} from "react-share";

import * as classes from "./Styles/ShareButtons.module.css";
import { useLocation } from "@reach/router";

const BUTTON_SIZE = 40;

const ShareButtons = () => {
  const location = useLocation();
  const [productUrl, setProductUrl] = useState(location);
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const productInitialStateFromFetch = useSelector(
    state => state.productReducer.productInitial,
    shallowEqual
  );

  const productCode = useSelector(
    state => state.productReducer.itemDetail.code,
    shallowEqual
  );

  useEffect(() => {
    if (productCode) {
      setImageUrl(
        `https://demob2b2c.avetti.io/preview/store/20180521148/assets/images/galleries/${productCode}.jpg`
      );
    }
  }, [productCode]);

  useEffect(() => {
    if (productInitialStateFromFetch.title != "") {
      setTitle(productInitialStateFromFetch.title);
    }
  }, [productInitialStateFromFetch]);

  return (
    <div className={classes.wrapper}>
      <EmailShareButton
        separator={" "}
        className="share-button"
        url={productUrl}
        subject={`This ${title} Awesome`}
        body={`Check this ${title}.`}
      >
        <img
          className="filter-outlookemail"
          style={{
            width: "38.5px"
          }}
          width="47"
          height="47"
          src="https://ik.imagekit.io/ofb/ofb/email_c08n2FXNH3.svg?tr=dpr-2,pr-true,f-auto,w-47"
          alt="email"
        />
      </EmailShareButton>
      <TwitterShareButton
        className="share-button"
        url={productUrl}
        title={`Check this ${title}.`}
        hashtags={["Avetti", "DemoStore", "B2B", "ecommerce"]}
      >
        <img
          className="filter-twitter"
          style={{
            width: "38.5px"
          }}
          aria-hidden="true"
          width="47"
          height="47"
          src="https://ik.imagekit.io/ofb/ofb/twitter_DkmqzF6MF.svg?tr=dpr-2,pr-true,f-auto,w-47"
          alt="Twitter"
          title="Twitter"
        />
      </TwitterShareButton>
      <FacebookShareButton
        className="share-button"
        url={productUrl}
        quote={`Check this ${title}.`}
        hashtags={["Avetti", "DemoStore", "B2B", "ecommerce"]}
      >
        <img
          className="filter-facebook"
          style={{
            width: "38.5px"
          }}
          width="47"
          height="47"
          src="https://ik.imagekit.io/ofb/ofb/facebook_ouzk_ScMN.svg?tr=dpr-2,pr-true,f-auto,w-47"
          alt="facebook"
        />
      </FacebookShareButton>
      <LinkedinShareButton
        className="share-button"
        url={productUrl}
        title={`This ${title} Awesome`}
        summary={`Check this ${title}.`}
        source={`Avetti Demo Store`}
      >
        <img
          className="filter-linkedin"
          style={{
            width: "38.5px"
          }}
          width="47"
          height="47"
          src="https://ik.imagekit.io/ofb/ofb/linkedin_f_OGzK1Z9.svg?tr=dpr-2,pr-true,f-auto,w-47"
          alt="LinkedIn"
        />
      </LinkedinShareButton>
      {/*<PinterestShareButton*/}
      {/*  className="share-button"*/}
      {/*  url={productUrl}*/}
      {/*  description={`Check this ${title}.`}*/}
      {/*  media={imageUrl}*/}
      {/*>*/}
      {/*  <img*/}
      {/*    className="filter-pinterest"*/}
      {/*    style={{*/}
      {/*      width: "38.5px"*/}
      {/*    }}*/}
      {/*    width="47"*/}
      {/*    height="47"*/}
      {/*    src="https://ik.imagekit.io/ofb/ofb/pinterest__FbW-B6_t.svg?tr=dpr-2,pr-true,f-auto,w-47"*/}
      {/*    alt="Pinterest"*/}
      {/*  />*/}
      {/*</PinterestShareButton>*/}

      {/*<LineShareButton*/}
      {/*  className="share-button"*/}
      {/*  url={productUrl}*/}
      {/*  title={`This ${title} Awesome`}*/}
      {/*  description={`Check this ${title}.`}*/}
      {/*>*/}
      {/*  <img*/}
      {/*    className="filter-lineshare"*/}
      {/*    style={{*/}
      {/*      width: "38.5px"*/}
      {/*    }}*/}
      {/*    width="47"*/}
      {/*    height="47"*/}
      {/*    src="https://ik.imagekit.io/ofb/ofb/line_mQt2JwetZ.svg?tr=dpr-2,pr-true,f-auto,w-47"*/}
      {/*    alt="Whatsapp"*/}
      {/*  />*/}
      {/*</LineShareButton>*/}
      {/*<WhatsappShareButton*/}
      {/*  separator={" "}*/}
      {/*  className="share-button"*/}
      {/*  url={productUrl}*/}
      {/*  title={`Check this ${title}.`}*/}
      {/*>*/}
      {/*  <img*/}
      {/*    className="filter-whatsapp"*/}
      {/*    style={{*/}
      {/*      width: "38.5px"*/}
      {/*    }}*/}
      {/*    width="47"*/}
      {/*    height="47"*/}
      {/*    src="https://ik.imagekit.io/ofb/ofb/whatsapp_R78MHvHF8.svg?tr=dpr-2,pr-true,f-auto,w-47"*/}
      {/*    alt="Whatsapp"*/}
      {/*  />*/}
      {/*</WhatsappShareButton>*/}
    </div>
  );
};

export default ShareButtons;
