import { FETCH_PRODUCT_BID_HISTORY, RESET_AUCTION_STATE } from "../types";
import { timeValSort } from "../../components/ACG-ProductPage/Components/Auction/utils.js";
import { LINK_DISTRIBUTION } from "../../project-config";

export const resetAuctionState = () => {
  return { type: RESET_AUCTION_STATE };
};

export const getProductBids = params => async dispatch => {
  const { acode, vid, itemcode } = params;
  fetch(
    `${LINK_DISTRIBUTION}/bidhistory.ajx?vid=${vid}&acode=${acode}&itemcode=${itemcode}`
  )
    .then(res => res.json())
    .then(async json => {
      if (!Object.keys(json.__Result).length) {
        return null; // halt the function as the backend returned emtpy result (in case of empty history for example)
      }
      let histories = json.__Result.histories;
      const auction = {
        startTime: json.__Result.startTime,
        endTime: json.__Result.endTime,
        acode: json.__Result.auctionCode,
        itemCode: json.__Result.itemCode,
        status: json.__Result.status
      };
      histories = await timeValSort(histories);
      const highestBid = histories[histories.length - 1];
      const payload = { histories, auction, highestBid };
      // console.log("voila", payload);
      await dispatch({ type: FETCH_PRODUCT_BID_HISTORY, payload });
    })
    .catch(err => {
      console.error("error fetching product details", err.message);
    });
};

export const addNewBid = params => async dispatch => {
  //alitest1
  const { acode, vid, itemcode, bidPrice, firstName, lastNameInitial, email } = params;
  console.log("Params", params);

  await fetch(
    `${LINK_DISTRIBUTION}/bidonitem.ajx?vid=${vid}&acode=${acode}&itemcode=${itemcode}&price=${bidPrice}&firstname=${firstName}&lastname=${lastNameInitial}`,
    {
      method: "POST"
    }
  )
    .then(res => res.json())
    .then(res => {
      console.log(res, { params, a: 13333 });
      if (res.__Success) {
        // this will dispatch a history fetched value to change => auction component re-renders.
        dispatch(getProductBids({ acode, vid, itemcode }));
      }
    })
    .catch(err => console.log(err, { params, a: 13333 }));
};
