// extracted by mini-css-extract-plugin
export var container = "Reviews-module--container--g1G2o";
export var ratingSide = "Reviews-module--ratingSide--7Jh7k";
export var ratingSideContainer = "Reviews-module--ratingSideContainer--f4bKA";
export var ratingStarsWrapper = "Reviews-module--ratingStarsWrapper--55fDG";
export var review = "Reviews-module--review--BjL6z";
export var reviewContent = "Reviews-module--reviewContent--UBxm2";
export var reviewDetails = "Reviews-module--reviewDetails--JaHNW";
export var reviewLinksContainer = "Reviews-module--reviewLinksContainer--hIrmP";
export var reviewStarAndBoxWrapper = "Reviews-module--reviewStarAndBoxWrapper--KdFc0";
export var reviewTitle = "Reviews-module--reviewTitle--kFY+w";
export var reviewedBy = "Reviews-module--reviewedBy--fY1+q";
export var reviews = "Reviews-module--reviews--EWJAv";
export var title = "Reviews-module--title--hCm-0";
export var totalReviewsWrapper = "Reviews-module--totalReviewsWrapper--8wdyp";
export var wrapper = "Reviews-module--wrapper--qwghr";