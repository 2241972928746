import React, { useMemo, useEffect } from "react";
import Content from "./Components/Content";
import Header from "./Components/Header";
import Actions from "./Components/Actions";
import { getProductBids } from "../../../../redux/actions/auctionActions";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import "./Components/Styles/Auction.css";

const Auction = ({ handleAddToCart }) => {
  const dispatch = useDispatch();

  const itemDetailState = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );

  const auctionCodeState = useSelector(
    state => state.productReducer.itemDetail.auction_code,
    shallowEqual
  );

  const priceInventoryState = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );

  const priceInventoryIsLoaded = useMemo(() => {
    return Object.keys(priceInventoryState).length > 0;
  }, [priceInventoryState]);

  const auctionState = useSelector(state => state.auctionReducer, shallowEqual);
  const { clientId } = useSelector(state => state.loginReducer, shallowEqual);

  let vid = itemDetailState.storevid;
  let itemcode = itemDetailState.code;

  const fetchBids = () => {
    let params = { acode: auctionCodeState, vid, itemcode };
    dispatch(getProductBids(params));
  };

  const start = () => {
    fetchBids();
    const ref = setInterval(() => {
      // console.log("---------- FETCHING THE HISTORY -----------");
      fetchBids(); // fetches the bid history each 1 second * period.
    }, 1000 * 5);

    return ref;
  };

  useEffect(() => {
    let ref = null;
    if (auctionCodeState && priceInventoryState?.prices) {
      // sufficient
      ref = start();
    }
    return () => {
      if (ref) clearInterval(ref);
    };
  }, [auctionCodeState, priceInventoryState?.prices, clientId]);

  if (!auctionState?.auctionFetched || auctionState?.status === "new") {
    return null;
  }

  if (priceInventoryIsLoaded)
    return (
      <div className="auction">
        <Header />
        <Content handleAddToCart={handleAddToCart} />
        {/*  <Actions /> */}
      </div>
    );
  else return null;
};

export default Auction;
