import React, { useEffect, useState } from "react";
import * as classes from "./Styles/Options.module.css";

const Options = ({ options, setButtonsDisabled }) => {
  const [radioButtonValue, setRadioButtonValue] = useState("-1");

  useEffect(() => {
    if (options) {
      if (options.length > 0) {
        setButtonsDisabled(true);
      } else {
        setButtonsDisabled(false);
      }
    }
  }, [options]);

  const handleChange = e => {
    const { value } = e.target;
    setRadioButtonValue(value);
  };

  console.info("radio", radioButtonValue);

  if (options.length > 0) {
    return (
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <div component="fieldset">
            <input
              type="radio"
              aria-label="options"
              value={radioButtonValue}
              onChange={handleChange}
            >
              {options.map(option => {
                return (
                  <label
                    key={option.optionid}
                    value={String(option.optionid)}
                    label={option.option}
                    className={classes.radioButton}
                  />
                );
              })}
            </input>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Options;
