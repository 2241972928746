import React from "react";

const PopUp = props => {
  const { visible, setVisible, submit, selectedBid, currUserIsWinner } = props;

  if (!visible) {
    return null;
  }

  return (
    <div className="PopUp">
      <div className="pu-content-container">
        <p>
          {currUserIsWinner ? (
            <strong
              style={{
                color: "red"
              }}
            >
              You already have the highest bid!
            </strong>
          ) : null}
        </p>
        {currUserIsWinner ? <br /> : null}
        <p>
          Are you sure you want to bid <strong>${selectedBid}</strong> on this
          item?
        </p>
        <br />
        <p>
          You will receive an email notification if you are outbid or if you win
          the auction.
        </p>
      </div>
      <div className="pu-button-container">
        <button className="submit" onClick={() => submit()}>
          Confirm
        </button>
        <button className="cancel" onClick={() => setVisible(false)}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default PopUp;
