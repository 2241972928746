// extracted by mini-css-extract-plugin
export var checkbox = "CheckBoxAddons-module--checkbox--z5cZ1";
export var checkboxWrapper = "CheckBoxAddons-module--checkboxWrapper--ECN21";
export var headerSub = "CheckBoxAddons-module--headerSub--wSUv5";
export var headerTitle = "CheckBoxAddons-module--headerTitle--0uDg-";
export var headerWrapper = "CheckBoxAddons-module--headerWrapper--TwJkG";
export var label = "CheckBoxAddons-module--label--AA+dD";
export var price = "CheckBoxAddons-module--price--v+fcw";
export var qtyControl = "CheckBoxAddons-module--qtyControl--AOWG-";
export var qtyInput = "CheckBoxAddons-module--qtyInput--HWNBT";
export var qtyWrapper = "CheckBoxAddons-module--qtyWrapper--lzWIN";
export var wrapper = "CheckBoxAddons-module--wrapper--po9H3";