import React, { useState, useMemo } from "react";
// import moment from "moment";
import { shallowEqual, useSelector } from "react-redux";
import { useCountDownTimer } from "../hooks";
import { timezoneFormatted } from "../utils";
import Actions from "./Actions";

const Content = ({ handleAddToCart }) => {
  const itemDetailState = useSelector(
    state => state.productReducer.itemDetail,
    shallowEqual
  );
  const auctionState = useSelector(state => state.auctionReducer, shallowEqual);

  const bidHistory = auctionState?.bidHistory || [];
  const highestBid = auctionState?.highestBid;
  const earlyCloseMinutes = +itemDetailState?.earlycloseminutes; // convert earlycloseminutes to numerical mins format
  const marketValue = +itemDetailState?.properties.find(prop => prop.propname === "MARKET_VALUE")?.propvalue;
  const { clientId } = useSelector(state => state.loginReducer, shallowEqual);

  const startTime = timezoneFormatted(auctionState?.startTime);
  let endTime = timezoneFormatted(auctionState?.endTime);

  /*
  if the auction is early closing (earlyCloseMinutes not = 0),
  we minus the amount of minutes from the date of closing and we show timer based on it
  */
  endTime = earlyCloseMinutes
    ? endTime.subtract(earlyCloseMinutes, "minutes")
    : endTime;

  let currentEndTime = timezoneFormatted(auctionState?.endTime);

  const auctionIsEnded =
    auctionState?.status === "closed" || endTime.isBefore(); // endTime.isBefore() && , disable auction actions only when status closed?
  const blockTimeIsEnded =
    currentEndTime.isBefore() || auctionState?.status === "closed";
  /*
    check whether the starting time of the auction is not in the future, cuz if so, we show a timer and we hide the ending timer,
    given that the auction is also new
    we do the same thing for the ending time, if auction has ended, we hide the buttons and we show winner messages.
  */
  let isStartInFuture = startTime?.isAfter();
  let isEndInPast = endTime?.isBefore();

  /*
  get client id, and check whether its the user with highest bid, then we show an approporiate name/msg
  */
  const [currUserIsWinner, setCurrUserIsWinner] = useState(false);

  useMemo(() => {
    setCurrUserIsWinner(clientId ? clientId === highestBid?.clientId : false);
  }, [clientId, highestBid]);

  const endingTimer = useCountDownTimer(endTime, bidHistory);
  const startingTimer = useCountDownTimer(startTime, bidHistory);
  // const countDownForEndTime = useCountDownTimer(currentEndTime, bidHistory);
  // const startingTimer = useCountDownTimer(startTime, bidHistory);
  const priceInventory = useSelector(
    state => state.productReducer.priceInventory,
    shallowEqual
  );
  const listPrice = marketValue || priceInventory?.prices?.[0].listprice;

  console.log("endingTimer", endTime, endingTimer);
  const renderEndingTime = type => {
    if (type === "starting") {
      return (
        <p className="time">
          {startingTimer.initial === false && (
            <p className="time">
              {startingTimer?.days !== "0" && (
                <>
                  {startingTimer?.days}{" "}
                  <span>{startingTimer?.days === "1" ? `day ` : `days `}</span>
                </>
              )}
              {startingTimer?.hours !== "0" && (
                <>
                  {startingTimer?.hours}{" "}
                  <span>
                    {startingTimer?.hours === "1" ? `hour ` : `hours `}
                  </span>
                </>
              )}
              {startingTimer?.minutes !== "0" && (
                <>
                  {startingTimer?.minutes}{" "}
                  <span>
                    {startingTimer?.minutes === "1" ? `minute ` : `minutes `}
                  </span>
                </>
              )}
              {startingTimer?.seconds !== "0" && (
                <>
                  {startingTimer?.seconds}{" "}
                  <span>
                    {startingTimer?.seconds === "1" ? `second ` : `seconds `}
                  </span>
                </>
              )}
            </p>
          )}
        </p>
      );
    } else {
      return (
        <p className="time">
          {endingTimer.initial === false && (
            <p className="time">
              {endingTimer?.days !== "0" && (
                <>
                  {endingTimer?.days}{" "}
                  <span>{endingTimer?.days === "1" ? `day ` : `days `}</span>
                </>
              )}
              {endingTimer?.hours !== "0" && (
                <>
                  {endingTimer?.hours}{" "}
                  <span>{endingTimer?.hours === "1" ? `hour ` : `hours `}</span>
                </>
              )}
              {endingTimer?.minutes !== "0" && (
                <>
                  {endingTimer?.minutes}{" "}
                  <span>
                    {endingTimer?.minutes === "1" ? `minute ` : `minutes `}
                  </span>
                </>
              )}
              {endingTimer?.seconds !== "0" && (
                <>
                  {endingTimer?.seconds}{" "}
                  <span>
                    {endingTimer?.seconds === "1" ? `second ` : `seconds `}
                  </span>
                </>
              )}
            </p>
          )}
        </p>
      );
    }
  };

  if (!auctionState?.auctionFetched || auctionState?.status === "new") {
    return null;
  }

  return (
    <div className="auction__content">
      {!isStartInFuture && (
        <div className="top">
          <span className="market-value">
            This item is Valued at: <strong>&#36;{listPrice}</strong>
          </span>
          <br />
          <span className="label">
            {bidHistory &&
              Array.isArray(bidHistory) &&
              bidHistory.length === 0 &&
              !auctionIsEnded &&
              "Be the first to bid."}
          </span>
          <span className="label">
            {highestBid && // bid history length > 0 => highest bid is not undefiend
              (currUserIsWinner ? (
                // "You have the highest bid"

                <>
                  <strong>You</strong> have the Highest bid at{" "}
                  <strong>&#36;{highestBid.bidPrice}</strong>
                </>
              ) : (
                <>
                  <strong>{highestBid?.firstName} {highestBid?.lastName[0].toUpperCase()}</strong> has the Highest bid
                  at <strong>&#36;{highestBid.bidPrice}</strong> (
                  {timezoneFormatted(highestBid?.timestamp).fromNow()})
                </>
              ))}
          </span>
        </div>
      )}
      {isStartInFuture && auctionState?.status === "open" && (
        <div className="top">
          <span className="label">Starts in</span>
          {/* notice how this one is slightly diff from the ending countdown timer, it is done on purpose to discuss which is better tomorrow */}
          {renderEndingTime("starting")}
        </div>
        // this timer shows as 00 days  34 hours  34 minutes  10 seconds, but the one below omits the ones that are 00, david says they're meaningless
      )}
      <Actions handleAddToCart={handleAddToCart} />
      {!isStartInFuture && !auctionIsEnded && (
        <div className="bottom">
          <span className="label">Auction ends in:</span>
          {renderEndingTime()}
        </div>
      )}
      {blockTimeIsEnded && (
        <>
          <span className="label" style={{ fontWeight: 600 }}>
            The auction for this item has ended.
          </span>
        </>
      )}
      {auctionIsEnded && !blockTimeIsEnded && (
        <>
          <span className="label" style={{ fontWeight: 600 }}>
            Bidding is closed for this item.
          </span>
        </>
      )}
    </div>
  );
};

export default Content;
