// extracted by mini-css-extract-plugin
export var container = "ReviewPhoto-module--container--hVq5n";
export var galleryViewPhotosWrapper = "ReviewPhoto-module--galleryViewPhotosWrapper--BhjBc";
export var photoModalBody = "ReviewPhoto-module--photoModalBody--zDgDx";
export var photoModalBodyContainer = "ReviewPhoto-module--photoModalBodyContainer--MXAlA";
export var photoModalContainer = "ReviewPhoto-module--photoModalContainer--MC686";
export var photoModalGalleryViewContainer = "ReviewPhoto-module--photoModalGalleryViewContainer--NzRnn";
export var photoModalHeader = "ReviewPhoto-module--photoModalHeader--iND5P";
export var photoModalImage = "ReviewPhoto-module--photoModalImage--KaodO";
export var photoModalImageArrowLeft = "ReviewPhoto-module--photoModalImageArrowLeft--hEDow";
export var photoModalImageArrowRight = "ReviewPhoto-module--photoModalImageArrowRight--thNv0";
export var photoModalImageArrowsWrapper = "ReviewPhoto-module--photoModalImageArrowsWrapper--yQ9kV";
export var photoModalImageBox = "ReviewPhoto-module--photoModalImageBox--pNiDl";
export var photoModalImageContainer = "ReviewPhoto-module--photoModalImageContainer--VFvjR";
export var photoModalImageWrapper = "ReviewPhoto-module--photoModalImageWrapper--uAjub";
export var photoModalReviewContainer = "ReviewPhoto-module--photoModalReviewContainer--17Ko3";
export var photoModalWrapper = "ReviewPhoto-module--photoModalWrapper--XLXdi";
export var reviewContent = "ReviewPhoto-module--reviewContent--XkhtJ";
export var reviewDetails = "ReviewPhoto-module--reviewDetails--Dw57g";
export var reviewImage = "ReviewPhoto-module--reviewImage--3jX0W";
export var reviewedBy = "ReviewPhoto-module--reviewedBy--l+kL7";
export var wrapper = "ReviewPhoto-module--wrapper--Nbvnx";