import React, { useState, useEffect } from "react";
import { countDownTimer } from "./utils";

export const useCountDownTimer = (srcDate, bidHistory) => {
  // we need the bidHistory ref so once it is changes we re-initialize our timers accordingly
  // const srcDate = moment().add(100, 'minutes');
  const [timer, setTimer] = useState({
    initial: true,
    seconds: "00",
    minutes: "00",
    hours: "00",
    days: "00"
  });

  const startTimer = () => {
    const ref = setInterval(() => {
      const { delta, days, hours, minutes, seconds } = countDownTimer(srcDate);
      // console.log(
      //   "test22",
      //   delta,
      //   days,
      //   hours,
      //   minutes,
      //   seconds,
      //   srcDate.format("YYYY-MM-DD HH:mm"),
      //   4949
      // );
      if (delta < 0) {
        clearInterval(ref);
      } else {
        setTimer(prevState => ({
          initial: false,
          days: days >= 10 ? days : `${days}`,
          hours: hours >= 10 ? hours : `${hours}`,
          minutes: minutes >= 10 ? minutes : `${minutes}`,
          seconds: seconds >= 10 ? seconds : `${seconds}`
        }));
      }
    }, 1000);

    return ref;
  };

  useEffect(() => {
    const ref = startTimer();
    // console.log("ref2", ref);

    return () => {
      if (ref) clearInterval(ref);
    };
  }, [bidHistory]);

  return timer;
};
