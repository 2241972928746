import React from "react";

function Header() {
  return (
    <div className="auction__header">
      <h2>Live Auction</h2>
    </div>
  );
}

export default Header;
