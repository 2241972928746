import React, { useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import * as classes from "./Styles/MessageForWinner.module.css";

export default function MessageForWinner() {
  const { loginName, firstName, securityToken, clientId } = useSelector(
    state => state.loginReducer,
    shallowEqual
  );
  const auctionState = useSelector(state => state.auctionReducer, shallowEqual);
  const highestBid = auctionState?.highestBid;
  const currUserIsWinner = useMemo(() => {
    return clientId && clientId === highestBid?.clientId;
  }, [highestBid]);

  const hiddenPropertiesState = useSelector(
    state => state.productReducer.itemDetail.hiddenProperties,
    shallowEqual
  );

  console.log("hiddenPropertiesState22", hiddenPropertiesState);

  const { pickupLocation, pickupAddress } = useMemo(() => {
    const pickupLocation = hiddenPropertiesState?.find(
      p => p.propname === "pickup_location"
    )?.propvalue;
    const pickupAddress = hiddenPropertiesState?.find(
      p => p.propname === "pickup_address"
    )?.propvalue;

    return { pickupLocation, pickupAddress };
  }, [hiddenPropertiesState]);

  if (currUserIsWinner)
    return (
      <div className={classes.wrapper}>
        <p>
          Visit the pickup location listed below or use the contact information
          to get more details on where to pickup your products.
        </p>
        <p>{pickupLocation}</p>
        <p>{pickupAddress}</p>
      </div>
    );
  else return null;
}
